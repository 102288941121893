﻿export default {
    //公用部分
    common: {
        confirm: '操作确认',
        tips: '提示',
        ok: '确定',
        cancel: '取消',
        this_week: '这一周',
        this_month: '当前月',
        last_three_months: '近三月',
        but_primary: '主要',
        but_default: '默认',
        but_success: '成功',
        but_warning: '警告',
        but_danger: '危险',
        but_info: '信息',
        but_text: '文本',
        note: '备注',
        add: '添加',
        delete: '删除',
        edit: '编辑',
        operation: '操作',
        save: '保存',
        save_child: '保存为下级',
        close: '关闭',
        clear: '清空',
        sort: '排序',
        child_sort: '下级排序',
        export: '导出',
        import: '导入',
        select_file: '选择文件',
        upload_fail: '文件上传失败！',
        import_success: '导入成功！',
        import_notexists: '要导入的文件不存在！',
        import_notjson: '要导入的文件不是有效的json格式文件！',
        import_fail: '导入失败！',
        print: '打印',
        return: '返回',
        
    },
    //组件
    components: {
        //组织架构选择组件
        org: {
            org: '组织架构',
            parttime: '兼任',
            workgroup: '工作组',
            search: '搜索',
            search_tip: '输入关键字搜索',
            search_result: '未搜索到与“',
            search_result1: '”相关的结果',
            search_result2: '搜索“',
            search_result3: '”的结果',
            select_size: '当前设置最多只能选择',
            select_size1: '项！',
        },
        //附件
        file: {
            upload: '上传',
            delete_confirm: '您确定要删除吗？',
            total_zero: '共0个文件',
            valid_type: '不允许上传的文件类型！',
            upload_fail: '文件上传失败！',
            valid_size: '当前设置最多只能添加',
            valid_size1: '个文件！',
            delete_success: '文件删除成功！',
            delete_fail: '文件删除失败！',
            delete_unknown: '未知错误！',
            valid_paramempty: '参数为空！',
            valid_notfound: '文件不存在！',
            total: '共',
            total1: '个文件',
        },
    },
    //登录页面
    login: {
        account: '帐号',
        password: '密码',
        lang: '语言',
        rememberme: '记住我',
        none: '不记住',
        oneday: '一天',
        aweek: '一周',
        login: '登录',
        validate_account: '请输入帐号',
        validate_password: '请输入密码',
        msg_force: '您的帐号已经在',
        msg_force1: '登录，您要强行登录吗？',
        msg_empty: '帐号或密码不能为空！',
        msg_error: '帐号或密码错误！',
        msg_frozen: '用户被冻结！',
        msg_frozen1: '用户所在组织 ',
        msg_frozen2: ' 被冻结！',
        msg_notfoundorg: '未找到您的所在组织！',
        msg_fail: '登录失败！',
    },
    //首页
    index: {
        set_menucolor: '菜单颜色',
        set_menucolor_light: '浅色',
        set_menucolor_drak: '深色',
        set_themecolor: '主题颜色',
        set_navcolor: '导航颜色',
        set_themecolor_theme: '主题色',
        set_themecolor_white: '白色',
        set_hometype: '首页布局',
        logout: '退出系统',
        download: '下载文件',
        todoitem: '待办事项',
        message: '消息',
        from: ' - 来自',
        logout_confirm: '您确定要退出系统吗？',
        loading: '加载中…',
        load_error: '加载页面发生了错误！',
        refresh: '刷新当前页',
        menu_expand: '展开菜单',
        menu_colse: '关闭菜单',
        ctxmenu_refresh: '刷新',
        ctxmenu_closecurrent: '关闭当前',
        ctxmenu_closeother: '关闭其他',
        ctxmenu_closeall: '关闭所有',
    },
    //流程处理
    flow_handle: {
        //公共
        common: {
            status_normal: '正常',
            status_overdue: '即将超期',
            status_expired: '已超期',
            status_serious: '严重超期',
            comment_common: '常用意见',
            comment_comment: '处理意见',
            handle_type: '处理类型',
            flow_status: '流程状态',
            flow_status_ing: '进行中',
            flow_status_end: '已结束',
            content: '消息内容',
            flow_chat: '流程图',
            task_type: '任务类型',
            task_type_normal: '常规',
            task_type_assign: '指派',
            task_type_entrust: '委托',
            task_type_redirect: '转交',
            task_type_back: '退回',
            task_type_copyfor: '抄送',
            task_type_beforeaddwrite: '前加签',
            task_type_afteraddwrite: '后加签',
            task_type_addwrite: '加签',
            task_type_goto: '跳转',
            task_type_solicitingopinions: '征求意见',
            task_handletype_wait: '待处理',
            task_handletype_ing: '处理中',
            task_handletype_completed: '已完成',
            task_handletype_otherhandle: '他人已处理',
            task_handletype_backed: '已退回',
            task_handletype_otherbacked: '他人已退回',
            task_handletype_redirect: '已转交',
            task_handletype_delegated: '已委托',
            task_handletype_read: '已阅知',
            task_handletype_assigned: '已指派',
            task_handletype_jumped: '已跳转',
            task_handletype_terminated: '已终止',
            task_handletype_otherterminated: '他人已终止',
            task_handletype_suspended: '已暂缓',
            task_handletype_othersuspended: '他人已暂缓',
            task_handletype_signed: '已加签',
            task_handletype_autoComplete: '自动完成',
            task_handletype_replied: '已回复',
        },
        //待办页面
        todo: {
            title: '标题',
            flow: '流程',
            receive_time: '接收时间',
            completed_time: '完成时间',
            init_time: '发起时间',
            start_time: '开始',
            end_time: '结束',
            query: '查询',
            step: '步骤',
            sender: '发送人',
            receiver: '接收人',
            handler: '处理人',
            status: '状态',
            complete_time: '要求完成时间 ',
            no_complete_time: '无要求完成时间',
            handle: '处理',
            batch_handle: '批量处理',
            process: '过程',
            form: '表单',
            urge: '催办',
            withdraw: '撤回',
            delete: '作废',
            delete_confirm: '您确定要作废吗？',
            delete_success: '作废成功！',
            delete_error: '执行作废发生了错误！',
            handle_pass: '通过',
            handle_back: '退回',
            handle_noselect: '请选择要批量处理的任务！',
            handle_comment: '请选输入处理意见',
            handle_selecttype: '请选择处理类型',
            handle_success: '成功批量处理了',
            handle_success1: '个任务！',
            handle_error: '批量处理发生了错误！',
            withdraw_confirm: '您确定要撤回吗？',
            withdraw_success: '撤回成功！',
            withdraw_handled: '后续任务已处理，不能撤回！',
            withdraw_error: '执行撤回发生了错误！',
            urge_type: '催办方式',
            urge_msg_receiver: '请选择接收人',
            urge_msg_type: '请选择催办方式',
            urge_msg_content: '请输入消息内容',
            urge_success: '催办成功！',
            urge_notfounduser: '未找到当前用户信息！',
            urge_notfoundtask: '未找到流程任务！',
            process_list: '过程列表',
            process_completed: '已完成',
            process_ing: '处理中',
            process_notarrived: '未到达',
            process_loadflowchartfail: '加载流程图失败！',
            entrust_time: '委托时间',
            entrust_withdraw: '收回',
            entrust_withdraw_confirm: '您确定要收回该任务吗？',
            entrust_withdraw_success: '收回成功，请在待办事项中查看该任务！',
            entrust_withdraw_handled: '该任务已处理，不能收回！',
            entrust_withdraw_fail: '收回失败！',
            start_useriderror: '用户id错误！',
            start_flowloadfail: '流程加载失败！',
            print_commnets: '打印处理意见',
            receive: '接收',
            current: '当前',
            manage: '管理',
            instance_manage: '实例管理',
            edit_status: '修改状态',
            edit_status_valid: '请选择状态',
            edit_status_success: '任务状态修改成功！',
            edit_status_fail: '修改流程任务状态发生了错误！',
            assign_success: '指派成功！',
            assign_fail: '指派失败！',
            goto_noselect: '您没有选择要跳转的步骤！',
            goto_success: '跳转成功！',
            goto_paramserror: '参数错误！',
            goto_fail: '跳转失败！',
            del_confirm: '您确定要删除该任务吗？',
        },
        //流程运行
        run: {
            inputcomments: '请输入意见',
            sign: '签章',
            signure: '请签章！',
            inputsignpwd: '输入签章密码',
            receive: '接收',
            notfoundflowrun: '未找到流程运行时！',
            notfoundsetprun: '未找到流程步骤运行时！',
            handleing: '当前任务正由',
            handleing1: '处理，请等待！',
            notauthview: '您无权查看当前任务！',
            cannotstart: '当前流程',
            cannotstart1: '不能发起新的实例！',
            notfounduser: '未找到用户登录信息！',
            notfoundtask: '未找到当前任务！',
            loaderror: '加载错误！',
            cannotempty: '不能为空',
            on: '开',
            off: '关',
            saved: '已保存！',
            sendto: '已发送到：',
            completedwait: '已完成，等待他人处理！',
            sendwait: '已发送，等待他人处理！',
            completedwaitstep: '已完成，等待其他步骤处理！',
            sendwaitstep: '已发送，等待其他步骤处理！',
            completed: '已完成！',
            sended: '已发送！',
            backto: '已退回到：',
            backwait: '已退回，等待他人处理！',
            backed: '已退回！',
            postponed: '已暂缓！',
            ended: '已终止！',
            copyfored: '已抄送给：',
            copyfored1: '已抄送！',
            solicitopinion: '已向：',
            solicitopinion1: '征求意见！',
            solicitopinioned: '已征求意见！',
            replyed: '已回复！',
            redirect1: '已转交给：',
            redirected: '已转交！',
            addwrite1: ' 加签！',
            addwrited: '已加签！',
            withdrawed: '已撤回！',
            cannotwithdrawed: '任务已处理，不能撤回！',
            handlesuccess: '处理成功！',
            paramerror: '参数错误！',
            flowiderror: '流程id错误！',
            knownhandletype: '未知的处理类型！',
            cannothandletask: '您不能处理该任务！',
            taskhandled: '当前任务已处理，不能再次处理！',
            handlerempty: '处理人为空！',
            cannotstarttask: '当前流程不能发起新的任务！',
            grouptaskempty: '当前实例组任务为空！',
            savedataerror: '保存表单数据时发生了错误！',
            startsubflorerror: '发起子流程发生了错误！',
            subflownocompleted: '子流程对应的任务未完成，不能提交！',
            notfoundcopyfotask: '未找到要抄送的步骤！',
            copyreceiverempty: '抄送接收人为空！',
            notfoundoptionstep: '未找到要征求意见的步骤！',
            optionreceiverempty: '征求意见接收人为空！',
            notfoundredirectstep: '未找到要转交的步骤！',
            redirectreceiverempty: '转交接收人为空！',
            notfoundaddwritestep: '未找到要加签的步骤！',
            addwritereceiverempty: '加签接收人为空！',
            cannotedeleteinstances: '不是发起人不能作废流程实例！',
            deleteinstanceerror: '作废流程实例发生错误！',
            cannotsetback: '当前步骤设置为不能退回！',
            backnotreceover: '退回没有找到接收人！',
            handlerflowerror: '处理流程发生了错误！',
            confirmsendstep: '确认接收步骤',
            confirmbakcstep: '确认退回步骤',
            process: '处理过程',
            flowchart: '流程图',
            savesethandler: '请先保存再指定处理人！',
            sethandler: '指定后续步骤处理人',
            taskurge: '任务催办',
            withdrawconfirm: '您确定要撤回吗？',
            postponeconfirm: '您确定要暂缓吗？',
            endconfirm: '您确定要终止吗？',
            copyfor: '抄送',
            solicitopinions: '征求意见',
            inputopinions: '请填写回复意见！',
            redirect: '转交',
            addwrite: '加签',
            selectaddwritereceiver: '选择加签接收人',
            stepinsend: '步骤内发送',
            notfoundstep: '未找到步骤！',
            notselectreceiver: '没有选择接收人！',
            noselectbackstep: '您没有选择要退回的步骤！',
            loadchartfail: '加载流程图失败！',
            selectcompletedtime: '选择完成时间',
            sendnotnextstep: '后续步骤均不符合条件！',
            notselectcompletedtime: '没有选择完成时间！',
            notselectsendstep: '您没有选择接收步骤！',
            selecthandler: '选择处理人',
            sethandlersuccess: '设置成功！',
            sethandlerfail: '设置失败！',
            sign_password: '密码',
            sing_password_valid: '请输入密码',
            sing_passwordempty: '密码不能为空！',
            sing_passworderror: '密码错误！',
            visibility: '可见性',
            visibility0: '仅自己可见',
            visibility1: '流程参与人可见',
            visibility_valid: '请选择可见性',
            note: '说明',
            solicitopinion_note: '接收人可看见您在这里输入的说明',
            view_reply: '查看回复',
            reply_time: '回复时间',
            reply_not: '未回复',
            solicitopinion_reply: '回复',
            form_valid_error: '表单验证错误，请检查表单必填项等信息！',
            completedconfirm: '您确定要完成吗？',
        },
    },
    //流程设计
    flow_design: {
        status: {
            unknown: '未知状态',
            indesign: '设计中',
            published: '已发布',
            uninstall: '已卸载',
            deleted: '已删除',
        },
        //委托
        entrust: {
            user: '委托人',
            receiver: '接收人',
            edit_time: '编辑时间',
            all_flow: '所有流程',
            all_note: '为空表示所有流程',
            valid_receiver: '请选择接收人',
            valid_starttime: '请选择开始时间',
            valid_endtime: '请选择结束时间',
            status_nostart: '未开始',
            status_ing: '委托中',
            status_end: '已结束',
            status_unknown: '未知',
            load_fail: '获取数据失败！',
            save_success: '保存成功！',
            save_fail: '保存失败！',
            datavalid_error: '数据验证错误！',
            delete_noselect: '您没有选择要删除的委托！',
            delete_confirm: '您确定要删除所选委托吗？',
            delete_success: '删除成功！',
            delete_fail: '删除失败！',
        },
        //意见
        comments: {
            use: '使用人',
            comments: '意见',
            all_user: '所有人员',
            source: '来源',
            user_add: '用户添加',
            admin_add: '管理员添加',
            sort: '排序',
            edit_note: '编辑时不能修改使用人员',
            validate_comment: '请输入意见',
            delete_noselect: '您没有选择要删除的意见！',
        },
        //接口系统
        apisystem: {
            name: '系统名称',
            identification: '系统标识',
            key: '调用KEY',
            update: '更新',
            valid_name: '请输入系统名称',
            valid_ident: '请输入系统标识',
            valid_key: '请输入调用KEY',
            delete_noselect: '您没有选择要删除的接口系统！',
            delete_confirm: '您确定要删除所选接口系统吗？',
        },
        //流程设计
        design: {
            hasdesign: '当前有流程正在设计中，请先关闭设计中的流程再打开该流程！',
            new: '新建流程',
            new1: '新建',
            design: '设计流程',
            delete_noselect: '您没有选择要删除的流程！',
            delete_confirm: '您确定要删除所选流程吗？',
            delete1: '彻底删除',
            delete1_noselect: '您没有选择要彻底删除的流程！',
            delete1_confirm: '您确定要彻底删除所选流程吗？',
            delete1_success: '彻底删除成功！',
            delete1_hastask: '已有实例任务，不能彻底删除！',
            delete1_fail: '彻底删除失败！',
            recovery: '恢复',
            recovery_confirm: '您确定要恢复该流程吗？',
            recovery_success: '恢复成功！',
            recovery_fail: '恢复失败！',
            export_noselect: '您没有选择要导出的流程！',
            name: '流程名称',
            step: '流程步骤',
            type: '流程分类',
            system: '所属系统',
            manager: '流程管理',
            instancemanager: '实例管理',
            debug: '调试模式',
            debug_user: '调试人员',
            icon: '流程图标',
            color: '颜色',
            color_note: '流程发起页面显示的图标和颜色',
            dbconn: '数据连接',
            dbtable: '数据表',
            tableprimary: '表主键',
            idevent: '标识事件',
            idfield: '标识字段',
            idvalue: '标识值',
            event_completed: '完成后事件',
            event_completed_note: '流程审批结束后执行',
            event_postpone: '暂缓后事件',
            event_postpone_note: '流程审批暂缓后执行',
            event_end: '终止后事件',
            event_end_note: '流程审批终止后执行',
            event_delete: '作废后事件',
            event_delete_note: '流程作废后执行',
            subflowstep: '子流程步骤',
            create_time: '创建时间',
            create_user: '创建人',
            attr: '属性',
            subflow: '子流程',
            line: '直线',
            brokenline: '折线',
            copy: '复制',
            copy_noselect: '请选择要复制的步骤！',
            link_noselect: '请选择要连接的步骤！',
            saveas: '另存为',
            saveas_success: '另存成功！',
            saveas_fail: '另存失败！',
            publish: '发布',
            uninstall: '卸载',
            valid_name: '请输入新流程名称',
            valid_name1: '流程名称不能为空，请在属性中设置！',
            valid_type: '流程分类不能为空，请在属性中设置！',
            valid_step: '流程至少需要一个步骤！',
            notfound: '未找到流程！',
            notauth: '您没有权限管理该流程！',
            openfail: '打开失败！',
            noflowtips: '请先新建或打开一个流程！',
            iduseriderror: '流程id或流程名称或用户id错误！',
            ing: '正在',
            success: '成功！',
            fail: '失败！',
            jsonerror: 'Json解析错误！',
            flowiderror: '流程id错误！',
            circulation_conditions: '流转条件',
            deleteobj_noselect: '请选择要删除的对象！',
            deleteobj_confirm: '您确认要删除选定对象吗？',
            line_data: '数据条件',
            line_dataexpression: '数据条件表达式',
            line_and: '并且',
            line_or: '或者',
            line_text: '条件标签',
            line_pdtype: '判断方式',
            line_text_note: '连线上显示的条件标签',
            line_org: '组织架构',
            line_orgexpression: '组织架构表达式',
            line_orgselect: '选择组织架构',
            line_sender: '发送者',
            line_initiator: '发起者',
            line_in: '属于',
            line_notin: '不属于',
            line_method: '自定义方法',
            line_method1: '方法',
            line_note1: '1、方法格式为：命名空间.类名.方法名。例：RProcess.Web.Controllers.TestController.TestLineCondition',
            line_note2: '2、方法返回布尔类型（bool）true或字符串类型（string）"true"、"1"时条件成立，否则条件不成立。',
            line_sql: 'SQL查询',
            line_sql_note1: '1、SQL查询有返回结果表示条件成立，否则条件不成立，SQL中可以写通配符。',
            line_sql_note2: '2、为了提高查询性能，SQL应当只返回一行一列数据。',
            line_conditions_dy: '等于',
            line_conditions_bdy: '不等于',
            line_conditions_day: '大于',
            line_conditions_dydy: '大于等于',
            line_conditions_xy: '小于',
            line_conditions_xydy: '小于等于',
            line_conditions_like: '包含',
            line_conditions_notlike: '不包含',
            line_conditions_subtablesumdy: '子表求和等于',
            line_conditions_subtablesumbdy: '子表求和不等于',
            line_conditions_subtablesumday: '子表求和大于',
            line_conditions_subtablesumdydy: '子表求和大于等于',
            line_conditions_subtablesumxy: '子表求和小于',
            line_conditions_subtablesumxydy: '子表求和小于等于',
        },
        //流程设计步骤基本
        step_base: {
            base: '基本',
            stepname: '步骤名称',
            showcomment: '意见显示',
            commentfile: '可传附件',
            commentfile_note: '填写处理意见时是否可以上传附件',
            autoconfirm: '有处理人直接发送',
            autoconfirm_note: '下一步有默认处理人时直接发送，不弹出发送确认窗口。',
            worktime: '工时(天)',
            sendsettiem: '发送时指定完成时间',
            signtype: '审签类型',
            signtype1: '无签批意见栏',
            signtype2: '有签批意见 - 无签章',
            signtype3: '有签批意见 - 须签章',
            signtype4: '有签批意见 - 须密码签章',
            expire: '超期提醒',
            expireday: '提前',
            expireday1: '天',
            expire_interval: '间隔',
            archive: '是否归档',
            batch: '批量处理',
            batch_note: '设置开启该步骤任务才在批量处理列表中显示',
            concurrent: '并发控制',
            concurrent_note: '步骤有多人处理时，是否同时只能有一人处理。',
            expirehandle: '超时处理',
            expirehandle1: '不处理',
            expirehandle2: '自动提交',
            sendmsg: '发送提示语',
            backmsg: '退回提示语',
            form: '表单',
            mobileform: '移动端表单',
        },
        //流程设计步骤策略
        step_behavior: {
            behavior: '策略',
            flowtype: '流转类型',
            flowtype1: '单选一个步骤',
            flowtype2: '多选几个步骤 - 默认选中第一个',
            flowtype3: '多选几个步骤 - 默认全部选中',
            flowtype4: '多选几个步骤 - 默认全部选中不能取消',
            flowtype5: '根据条件判断 - 无后续步骤时提示',
            flowtype6: '根据条件判断 - 无后续步骤时完成',
            selecttype: '选择类型',
            selecttype_org: '组织选择类型',
            selecttype_org_unit: '单位',
            selecttype_org_dept: '部门',
            selecttype_org_station: '岗位',
            selecttype_org_user: '人员',
            selecttype_org_wg: '工作组',
            runselect: '允许选择',
            runselect_note: '是否允许发送人在处理时自己选择该步骤的接收人',
            selectquantity: '数量',
            selectquantity_note: '发送时允许选择的组织人员数量，0或空表示不限制。',
            handlertype: '处理者类型',
            selectrange: '选择范围',
            selectrange1: '默认处理者选中',
            selectrange2: '默认处理者不选中',
            selectrangeset: '指定选择范围',
            handlersetp: '处理者步骤',
            handlersetp_note: '处理者类型为某一步时的步骤',
            addwriterange: '加签范围',
            addwriterange_note: '指定加签选择范围',
            defaulthandler: '默认处理人',
            defaulthandler_note: '将该步骤的默认处理人作为加签选择范围',
            valuefield: '值字段',
            lasthandler: '上次处理者',
            lasthandler_note: '如果没有找到默认处理者，则读取步骤上次的处理人作为默认处理者。',
            defaultsql: '默认处理者',
            defaultsql1: 'SQL或方法',
            defaultsql_note: '[sql]开头的字符串表示为sql语句，否则表示为方法。',
            defaulthandler1: '默认处理者',
            defaultset: '指定默认处理者',
            handlestrategy: '处理策略',
            handlestrategy0: '所有人同意',
            handlestrategy1: '一人同意即可',
            handlestrategy2: '依据人数比例',
            handlestrategy3: '按选择人员顺序处理',
            handlestrategy4: '独立处理',
            percentage: '百分比',
            backstrategy: '退回策略',
            backstrategy1: '根据处理策略退回',
            backstrategy2: '一人退回全部退回',
            backstrategy3: '所有人退回才退回',
            backstrategy4: '独立退回',
            backstrategy0: '不能退回',
            backselect: '选择退回接收人',
            backselect_note: '退回时处理人自己选择接收人',
            counterstrategy: '会签策略',
            counterstrategy0: '不会签',
            counterstrategy1: '所有步骤同意',
            counterstrategy2: '一个步骤同意即可',
            counterstrategy3: '依据比例',
            backtype: '退回类型',
            backtype0: '退回前一步',
            backtype1: '退回第一步',
            backtype2: '退回某一步',
            sendtoback: '发送到退回步骤',
            sendtoback_note: '如果是退回任务时直接发送到退回的步骤',
            counterstart: '会签起点',
            counterstart_note: '选择会签起点步骤',
            backstep: '退回步骤',
            backstep_note: '选择退回类型为"退回某一步"时的步骤',
            skipstrategy: '跳过策略',
            skip_user: '同一处理人',
            skip_user_note: '上一步发送人和该步接收人为同一人时跳过',
            skip_nouser: '无默认处理人',
            skip_nouser_note: '没有找到默认处理人时跳过',
            skip_method: '方法',
            skip_method_note: '方法返回布尔true或字符串"1"或"true"时跳过',
        },
        //流程设计步骤按钮
        step_button: {
            button: '按钮',
            remove: '移出',
            tips: '操作提示',
            tips_addremove: '在按钮上双击可快速添加和移出按钮。',
            tips_sort: '在已选择按钮上拖动可排序。',
            display: '按钮显示',
            note: '按钮说明',
            separator: '分隔线',
            add_noselect: '请选择要添加的按钮！',
            add_selected: '按钮已经选择了！',
            remove_noselect: '请选择要移出的按钮！',
            notfoundstep: '没有找到步骤！',
            handlerType0: '发起者',
            handlerType1: '某一步骤处理者',
            handlerType2: '字段值',
            handlerType3: '发起者 - 部门',
            handlerType4: '发起者 - 单位',
            handlerType5: '前一步处理者 - 部门',
            handlerType6: '前一步处理者 - 单位',
            initiator_parents: '发起者 - 所有上级',
            prevhandler: '前一步处理者',
            prevhandler_parents: '前一步处理者 - 所有上级',
        },
        //流程设计步骤数据
        step_data: {
            data: '数据',
            table: '数据表',
            status: '状态',
            edit: '编辑',
            readonly: '只读',
            hide: '隐藏',
            valid: '数据验证',
            valid_no: '不验证',
            valid_yes: '验证',
            synctotable: '更新到数据表',
            synctotable_note: '此步骤执行流程时是否将表单数据同步更新到绑定的数据表',
            field: '字段',
            fieldstatus: '字段状态',
        },
        //流程设计步骤抄送
        step_copyfor: {
            copyfor: '抄送',
            setreceiver: '指定接收人',
            setreceover_note: '抄送给指定的接收人',
            type: '类型',
            stephandler: '步骤处理人员',
            stephandler_note: '抄送给某一步骤的处理人员',
            sqlormethod: 'SQL或方法',
            sqlormethod_note: '通过sql或方法返回接收人，[sql]开头表示sql语句，否则表示方法。',
            time: '抄送时间',
            time_receive: '步骤接收时',
            time_completed: '步骤完成时',
            msg: '待办通知',
            msgtemplete: '消息模板',
        },
        //流程设计步骤事件
        step_event: {
            event: '事件',
            submit_before: '提交前事件',
            submit_before_note: '方法返回字符串1、true以外的其他字符，表示为错误信息，返回客户端提示，任务不提交。',
            submit_after: '提交后事件',
            submit_after_note: '任务提交后执行',
            pass_after: '步骤提交通过后事件',
            pass_after_note: '步骤提交通过后执行',
            back_before: '退回前事件',
            back_before_note: '方法返回字符串1、true以外的其他字符，表示为错误信息，返回客户端提示，任务不退回。',
            back_after: '退回后事件',
            back_after_note: '任务退回后执行',
            back_pass: '步骤退回通过后事件',
            back_pass_note: '步骤退回通过后执行',
        },
        //流程设计步骤子流程
        step_subflow: {
            subflow: '子流程',
            behavior: '子流程策略',
            behavior0: '子流程完成才能提交',
            behavior1: '子流程发起即可提交',
            behavior2: '子流程发起后自动提交',
            behavior3: '子流程完成后自动提交',
            instancetype: '实例类型',
            instancetype0: '所有人同一实例',
            instancetype1: '每个人单独实例',
            activeevent: '激活前事件',
            activeevent_note: '发起子流程任务前设置子流程业务数据，任务标题等操作。',
        },
    },
    //表单设计
    form: {
        name: '表单名称',
        delete_noselect: '您没有选择要删除的表单！',
        delete_confirm: '您确定要删除所选表单吗？',
        delete1_noselect: '您没有选择要彻底删除的表单！',
        delete1_confirm: '您确定要彻底删除所选表单吗？',
        recovery_confirm: '您确定要恢复该表单吗？',
        export_noselect: '您没有选择要导出的表单！',
        load_fail: '获取表单信息失败！',
        attr: '属性',
        ctl: '控件',
        bind_field: '绑定字段',
        label: '标签',
        default_value: '默认值',
        default_value_note: '选择默认值',
        width: '宽度',
        width_note: '%或px',
        align: '对齐方式',
        align_left: '左对齐',
        align_center: '居中',
        align_right: '右对齐',
        disabled: '禁用',
        readonly: '只读',
        clearable: '可清除',
        maxlength: '最大字符',
        placeholder: '背景文字',
        prefix: '前缀图标',
        suffix: '后缀图标',
        input_type: '输入类型',
        input_text: '明文',
        input_password: '密码',
        style: '样式',
        event: '事件',
        script: '脚本',
        repeat: ' 重复！',
        search: '搜索',
        source: '选项来源',
        source_string_note: '格式：选项1值,选项1标题;选项2值,选项2标题...（字符串不能有单引号)',
        source_sql_note: '查询返回两个字段，选项值和选项标题。如果只返回一个字段，则选项值和选项标题一样。',
        source_url_note: 'post一个url地址，地址返回json字符串。',
        source_sqlurl: '加载下级SQL/URL',
        dictchild: '数据字典下级',
        multiple: '多选',
        dbconn: '数据连接',
        dbconn_note: '选择执行SQL的数据连接',
        dictitem: '字典项',
        valuefield: '值字段',
        linkage: '联动',
        input: '输入框',
        input_attr: '输入框属性',
        input_delete: '删除输入框',
        textarea: '文本域',
        textarea_attr: '文本域属性',
        textarea_delete: '删除文本域',
        textarea_rows: '行数',
        inputnumber: '数字输入框',
        inputnumber_attr: '数字输入框属性',
        inputnumber_delete: '删除数字输入框',
        inputnumber_step: '步长',
        inputnumber_precision: '小数位',
        inputnumber_stepstrictly: '只能输入步长的倍数',
        inputnumber_min: '最小值',
        inputnumber_max: '最大值',
        inputnumber_showbutton: '显示控制按钮',
        inputnumber_controlsposition: '按钮在右边',
        switch: '开关',
        switch_attr: '开关属性',
        switch_delete: '删除开关',
        switch_text: '选中文本',
        switch_text1: '非选中文本',
        switch_inlineprompt: '是否显示在点内',
        switch_value: '选中时值',
        switch_value1: '非选中时值',
        switch_defaultselect: '默认是否选中',
        switch_color: '选中颜色',
        switch_color1: '非选中颜色',
        select: '下拉选择',
        select_attr: '下拉选择属性',
        select_delete: '删除下拉选择',
        cascader: '级联选择',
        cascader_attr: '级联选择属性',
        cascader_delete: '删除级联选择',
        cascader_expandtrigger: '展开方式',
        cascader_expandtrigger_click: '单击',
        cascader_expandtrigger_hover: '移入',
        radio: '单选按钮',
        radio_attr: '单选按钮属性',
        radio_delete: '删除单选按钮',
        rodio_style_normal: '常规',
        rodio_style_button: '按钮',
        rodio_style_border: '边框',
        checkbox: '复选按钮',
        checkbox_attr: '复选按钮属性',
        checkbox_delete: '删除复选按钮',
        hidden: '隐藏域',
        hidden_attr: '隐藏域属性',
        hidden_delete: '删除隐藏域',
        datetime: '日期时间',
        datetime_attr: '日期时间属性',
        datetime_delete: '删除日期时间',
        datetime_selecttype: '选择类型',
        datetime_date: '日期',
        datetime_daterange: '日期范围',
        datetime_datetimerange: '日期时间范围',
        datetime_year: '年',
        datetime_month: '月',
        datetime_monthrange: '月范围',
        datetime_week: '周',
        datetime_dates: '多个日期',
        datetime_shortuct: '快捷选项',
        datetime_separator: '分隔符',
        datetime_separator_note: '-',
        datetime_showformat: '显示格式',
        datetime_showformat_note: '例：YYYY-MM-DD',
        datetime_valueformat: '值格式',
        datetime_time: '时间 - time',
        datetime_time_start: '开始时间',
        datetime_time_end: '结束',
        datetime_time_min: '最早',
        datetime_time_max: '最晚',
        datetime_time_step: '步长',
        dict: '数据字典',
        dict_attr: '数据字典属性',
        dict_delete: '删除数据字典',
        dict_selectset: '选择设置',
        dict_showroot: '显示根',
        dict_selectroot: '选择根',
        dict_selectparent: '选择父节点',
        dict_multiple: '多选',
        dict_selectsize: '选择个数',
        dict_selectsize_note: '0或空不限制',
        dict_selectrange: '选择范围',
        org: '组织架构',
        org_attr: '组织架构属性',
        org_delete: '删除组织架构',
        org_company: '单位',
        org_dept: '部门',
        org_station: '岗位',
        org_user: '用户',
        org_workgroup: '工作组',
        org_selectrange0: '发起者部门',
        org_selectrange1: '发起者单位',
        org_selectrange2: '处理者部门',
        org_selectrange3: '处理者单位',
        org_setrange: '指定范围',
        file: '附件',
        file_attr: '附件属性',
        file_delete: '删除附件',
        file_type: '文件类型',
        file_type_note: '可上传的文件类型，例：.jpg,.png,.docx等。',
        file_quantity: '文件数量',
        file_showtype: '显示类型',
        file_showtype_link: '链接',
        file_showtype_linkbr: '链接换行',
        file_showpicture: '显示图片',
        file_showindex: '显示序号',
        file_readonlyshowtype: '只读时的显示方式',
        file_showstyle: '显示样式',
        file_showstyle_note: '显示时的css样式',
        file_ctltype: '控件类型',
        file_ctltype_div: '弹出层',
        file_ctlshowtype: '显示方式',
        file_ctlshowtype_list: '列表',
        file_ctlshowtype_avatar: '头像',
        file_ctlshowtype_picturecard: '照片墙',
        file_ctltip: '提示',
        file_ctltip_note: '对上传文件的要求说明等提示',
        file_buttext: '按钮文字',
        appraise: '评分',
        appraise_attr: '评分属性',
        appraise_delete: '删除评分',
        appraise_icon: '图标',
        appraise_total: '总数',
        appraise_allowhalf: '允许半选',
        html: 'Html编辑器',
        html_attr: 'Html编辑器属性',
        html_delete: '删除Html编辑器',
        html_height: '高度',
        html_number: '数字',
        html_toolbar: '工具栏',
        html_toolbar_standard: '标准',
        html_toolbar_pithy: '简洁',
        label1: 'Label标签',
        label_attr: 'Label标签属性',
        label_delete: '删除Label标签',
        button: '按钮',
        button_attr: '按钮属性',
        button_delete: '删除按钮',
        button_type: '类型',
        snumber: '流水号',
        snumber_attr: '流水号属性',
        snumber_delete: '删除流水号',
        snumber_note: '选择流水号',
        snumber_placeholder: '没有流水号时显示的提示文字',
        dt: '数据表格',
        dt_attr: '数据表格属性',
        dt_delete: '数据表格删除',
        dt_size: '尺寸',
        dt_size_default: '默认',
        dt_size_large: '大',
        dt_size_small: '小',
        dt_stripe: '斑马纹',
        dt_source: '数据来源',
        dt_columnset: '列设置',
        selectdiv: '弹出选择',
        selectdiv_attr: '弹出选择属性',
        selectdiv_delete: '删除弹出选择',
        selectdiv_page: '弹出页面',
        selectdiv_page_note: '请选择应用分类',
        delectdiv_valuefield: '值字段',
        selectdiv_titlefield: '标题字段',
        selectdiv_valueseparator: '值分隔符',
        selectdiv_valueseparator_note: '多选时多个值之间的分隔符',
        selectdiv_titleseparator: '标题分隔符', 
        selectdiv_titleseparator_note: '多选时多个标题之间的分隔符',
        selectdiv_wintitle: '窗口标题',
        selectdiv_width: '控件宽度',
        selectdiv_winwidth: '窗口宽度',
        selectdiv_params: '参数',
        selectdiv_params_note: 'js表达式，例：\'size=5&test=\'+this.formData[\'id\']',
        sign: '签章',
        sign_attr: '签章属性',
        sign_delete: '删除签章',
        sign_buttontype: '按钮类型',
        sign_delfault: '默认已签',
        saveas: '表单另存为',
        saveas_note: '要另存为的表单名称',
        saveas_notformattr: '未找到表单属性！',
        saveas_nameempty: '表单名称不能为空！',
        saveas_ing: '正在保存…',
        saveas_success: '保存成功！',
        saveas_iderror: '表单id错误！',
        saveas_from: '未找到源表单！',
        saveas_notuser: '未找到当前用户！',
        saveas_attrerror: '表单属性解析错误！',
        saveform: '保存表单',
        save_type: '表单分类不能为空！',
        previvew: '预览表单',
        previvew_save: '请先保存表单，再预览！',
        publish: '发布表单',
        publish_ing: '正在发布…',
        publish_success: '发布成功！',
        publish_fail: '发布失败！',
        attribute: '表单属性',
        attribute_manager: '管理人员',
        attribute_dbconn: '数据连接',
        attribute_table: '数据表',
        attribute_key: '主键',
        attribute_title: '标题字段',
        attribute_expression: '标题表达式',
        attribute_expression_note: '',
        attribute_type: '表单分类',
        attribute_labelwidth: '标签宽度',
        attribute_labelwidth_note: '默认auto',
        attribute_labelsuffix: '标签后缀',
        attribute_labelsuffix_note: '默认无',
        attribute_valid: '检验信息',
        attribute_valid_show: '是否显示',
        attribute_valid_inline: '是否行内显示',
        attribute_valid_showicon: '是否显示图标',
        attribute_border: '表格是否有边框',
        subtable: {
            subtable: '子表',
            subtable_attr: '子表属性',
            subtable_delete: '删除子表',
            primarykey: '子表主键',
            mainfield: '主表字段',
            linkfield: '关联字段',
            tablesize: '表格大小',
            field: '字段',
            showtitle: '显示标题',
            align: '对齐',
            align_left: '左对齐',
            align_center: '居中',
            align_right: '右对齐',
            editcontrol: '编辑控件',
            sumcolumn: '合计',
            showindex: '显示顺序',
            remove: '移出',
            controltype: '控件类型',
            notfoundedit: '未找到editor对象！',
            settitle: '设置子表控件',
            label: '标签',
            sum: '合计',
        },
        //form-design.js中的
        design: {
            string: '字符',
            number: '数字',
            thousand: '千分符',
            percentage: '百分比',
            title: '标题',
            value: '值',
            code: '唯一代码',
            note: '备注',
            other: '其他',
            dict: '数据字典',
            auto_number: '自动生成流水号',
            df_org: '组织架构相关选项',
            df_org_userid: '当前用户id',
            df_org_username: '当前用户姓名',
            df_org_useraccount: '当前用户帐号',
            df_org_deptid: '当前用户所在部门id',
            df_org_deptname: '当前用户所在部门名称',
            df_org_stationid: '当前用户所在岗位id',
            df_org_stationname: '当前用户所在岗位名称',
            df_org_companyid: '当前用户所在单位id',
            df_org_companyname: '当前用户所在单位名称',
            df_org_org: '当前用户组织架构关系',
            df_org_orgroot: '当前用户组织架构关系包含根',
            df_datetime: '日期时间相关选项',
            df_datetime_shortdate: '日期(yyyy-MM-dd)',
            df_datetime_longdate: '日期(yyyy年M月d日)',
            df_datetime_shortdatetime: '日期时间(yyyy-MM-dd HH:mm)',
            df_datetime_longdatetime: '日期时间(yyyy年M月d日 H时m分)',
            df_datetime_shortdatetimes: '日期时间(yyyy-MM-dd HH:mm:ss)',
            df_datetime_longdatetimes: '日期时间(yyyy年M月d日 H时m分s秒)',
            df_flow: '流程相关选项',
            df_flow_id: '当前流程id',
            df_flow_name: '当前流程名称',
            df_flow_stepid: '当前步骤id',
            df_flow_stepname: '当前步骤名称',
            df_flow_taskid: '当前任务id',
            df_flow_tasktitle: '当前任务标题',
            df_flow_instanceid: '当前实例id（业务数据id）',
            df_flow_groupid: '当前审批分组id（GroupId）',
            df_flow_receiverid: '当前任务接收者id',
            df_flow_receivername: '当前任务接收者姓名',
        }
    },
    //消息中心
    message: {
        type: {
            site: '站内消息',
            phone: '手机短信',
            qywechat: '企业微信',
            mail: '邮件',
        },
        receiver: '接收人员',
        send_type: '发送方式',
        contents: '消息内容',
        send: '发送',
        valid_receiver: '请选择接收人',
        valid_sendtype: '请选择发送方式',
        valid_contents: '请输入消息内容',
        msg_content: '消息内容不能为空！',
        msg_receiver: '消息接收人员不能为空！',
        msg_success: '发送成功！',
        msg_fail: '发送失败！',
        mark: '全部标记为已读',
        flowtask: '流程任务',
        mark_no: '没有需要标记的消息！',
        mark_success: '标记成功！',
        mark_fail: '标记失败！',
        delete_noselect: '您没有选择要删除的消息！',
        delete_confirm: '您确定要删除所选消息吗？',
        unread: '未读',
        read_time: '阅读时间 ',
        read: '已读',
    },
    //系统管理
    system: {
        //应用程序库
        app: {
            title: '应用标题',
            traditional: '繁体标题',
            english: '英语标题',
            address: '应用地址',
            type: '应用分类',
            open_mode: '打开方式',
            width: '窗口宽度',
            height: '窗口高度',
            select: '选择',
            button_edit: '编辑应用按钮',
            button_type_normal: '常规按钮',
            button_type_list: '列表按钮',
            delete_noselect: '请选择要删除的应用！',
            delete_confirm: '您确定要删除所选应用吗？',
            valid_title: '请输入应用标题',
            valid_address: '请输入应用地址',
            valid_type: '请选择应用分类',
            valid_openmode: '请选择应用打开方式',
            load_fail: '获取应用程序数据失败！',
            load_button_fail: '获取应用程序按钮失败！',
            export_noselect: '您没有选择要导出的应用！',
            button: '按钮',
        },
        //组织架构
        org: {
            name: '姓名',
            org: '组织',
            login_time: '登录时间',
            activity_time: '活动时间',
            browser: '客户端信息',
            login_type: '登录类型',
            offline: '离线',
            pc: '电脑端',
            wechat: '企业微信',
            offline_confirm: '您确定要将该用户强制离线吗？',
            operation_success: '操作成功！',
            operation_fail: '操作失败！',
            user_phone: '手机',
            user_mail: '邮箱',
            user_tel: '电话',
            user_birthday: '生日',
            user_concat: '其他联系方式',
            user_loadfail: '获取用户信息失败！',
            user_editpass_old: '旧密码',
            user_editpass_new: '新密码',
            user_editpass_newconfirm: '确认新密码',
            user_editpass_valid_newpass: '请输入确认新密码',
            user_editpass_valid_two: '两次密码输入不一致',
            user_editpass_valid_old: '请输入旧密码',
            user_editpass_valid_new: '请输入新密码',
            user_editpass_success: '修改成功！',
            user_editpass_notfound: '未找到用户！',
            user_editpass_olderror: '旧密码错误！',
            user_editpass_newempty: '新密码为空！',
            user_editpass_fail: '修改失败！',
            user_sign: '签章',
            user_sign_set: '设置',
            avatar_upload: '上传',
            avatar_save: '保存头像',
            avatar_default: '恢复默认',
            avatar_valid_picture: '必须是图片格式！',
            avatar_valid_size: '上传的图片文件不能大于2M！',
            avatar_no: '没有要保存的头像！',
            avatar_reset_success: '恢复成功！',
            avatar_reset_fail: '恢复失败！',
            shortuct_tips: '提示：不能设置没有绑定应用的菜单为快捷菜单。已选择快捷菜单拖拽可排序。',
            shortuct_noselect: '请选择要添加的菜单！',
            shortuct_nobind: '不能设置没有绑定应用的菜单为快捷菜单！',
            shortuct_selected: '当前菜单已经选择了！',
            shortuct_deletenoselect: '请选择要移出的快捷菜单！',
            operation_tips: '请在左边选择要操作的组织',
            search: '搜索',
            search_note: '输入关键字搜索',
            search_result: '未搜索到与“',
            search_result1: '”相关的结果',
            search_result2: '搜索“',
            search_result3: '”的结果',
            workgroup: '工作组',
            type: '类型',
            type_unit: '单位',
            type_dept: '部门',
            type_station: '岗位',
            status: '状态',
            status_normal: '正常',
            status_frozen: '冻结',
            move: '移动',
            moveto: '移动到',
            moveto_note: '请选择要移动到的组织',
            moveto_confirmnote: '您确定要移动吗？',
            moveto_confirm: '确定移动',
            moveto_success: '移动成功！',
            moveto_empty: '要移动的组织为空！',
            moveto_self: '不能移动到自己或自己的下级组织！',
            adduser: '添加人员',
            setmenu: '菜单授权',
            setmenu_show: '查看菜单授权',
            syncwechat: '同步到企业微信',
            syncwechat_confirm: '您确定要同步整个组织架构到企业微信吗？',
            syncwechat_completed: '同步完成！',
            syncwechat_fail: '同步失败！',
            valid_name: '请输入名称',
            valid_type: '请选择类型',
            valid_status: '请选择状态',
            org_notfound: '未找到组织架构！',
            save_confirm: '您确定要保存',
            save_confirm1: '为下级',
            save_confirm2: '吗？',
            org_delete_confirm: '此操作将会删除该组织及其所有下级组织，您确定要删除吗？',
            org_delete_root: '不能删除组织架构根！',
            org_sort: '当前组织没有下级组织，勿需排序！如要对人员排序，请点击人员！',
            org_sortone: '当前组织只有一个下级组织，勿需排序！如要对人员排序，请点击人员！',
            menu_source: '来源',
            account: '帐号',
            gender: '性别',
            gender_man: '男',
            gender_woman: '女',
            transfer: '调动到',
            transfer1: '调动',
            transfor_select: '请选择要调往的组织',
            transfer_confirm: '您确定要调动吗？',
            transfer_success: '调动成功！',
            transfer_fail: '调动失败！',
            parttime: '兼任',
            transfor_confirm: '确定调动',
            inorg: '所在组织',
            initpassword: '初始密码',
            initpassword_confirm: '您确定要初始化密码吗？',
            initpassword_tips: '密码已初始化为：',
            initpassword_fail: '初始化密码失败！',
            valid_user_name: '请输入姓名',
            valid_user_account: '请输入帐号',
            valid_user_gender: '请选择性别',
            valid_user_status: '请选择状态',
            valid_user_accountuse: '帐号已经被使用！',
            delete_confirm: '您确定要删除吗？',
            delete_no: '当前人员存在流程任务，不能删除！',
            user_sort_one: '当前组织下只有一个人员，勿需排序！',
            user_load_fail: '加载人员错误！',
            member: '成员',
            workgroup_notfound: '未找到工作组！',
            iderror: 'id错误！',
            workgroup_deleteno: '不能删除根工作组！',
        },
        //菜单管理
        menu: {
            name: '菜单名称',
            traditional: '繁体名称',
            english: '英语名称',
            app: '关联应用',
            icon: '菜单图标',
            color: '图标颜色',
            update_auth: '更新菜单权限',
            opeation_tips: '请点击左边菜单进行操作',
            valid_title: '请输入菜单名称',
            notfound: '未找到菜单！',
            load_fail: '加载数据失败！',
            save_confirm: '您确定要保存',
            save_confirm1: '为下级',
            save_confirm2: '吗？',
            delete_noselect: '请选择要删除的菜单！',
            delete_confirm: '此操作将会删除该菜单及其所有下级菜单，您确定要删除吗？',
            update_success: '更新成功！',
            update_fail: '更新失败！',
            sort_nochild: '当前菜单没有下级菜单，勿需排序！',
            sort_one: '当前菜单只有一个下级菜单，勿需排序！',
            sort_success: '保存排序成功！',
            sort_fail: '保存排序失败！',
        },
        //数据连接
        dbconn: {
            system: '系统连接',
            primarytable: '主表',
            name: '连接名称',
            type: '连接类型',
            conn_string: '连接字符串',
            test: '测试',
            valid_name: '请输入连接名称',
            valid_type: '请选择连接类型',
            valid_string: '请输入连接字符串',
            delete_select: '请选择要删除的数据连接！',
            delete_confirm: '您确定要删除所选数据连接吗？',
            test_success: '连接数据库成功！',
            test_fail: '连接数据库失败，请检查数据库服务器是否正常以及连接字符串是否正确！',
        },
        //系统按钮库
        button: {
            name: '名称',
            name_tw: '繁体名称',
            name_en: '英语名称',
            icon: '图标',
            script: '脚本',
            script_note: '按钮点击执行的js脚本',
            type: '类型',
            address: '操作地址',
            address_note: '按钮对应的后端操作地址，验证权限用。',
            valid_name: '请输入按钮名称',
            valid_script: '请输入按钮脚本',
            delete_select: '请选择要删除的按钮！',
            delete_confirm: '您确定要删除所选按钮吗？',
        },
        //数据字典
        dict: {
            title: '字典标题',
            title_tw: '繁体标题',
            title_en: '英语标题',
            code: '唯一代码',
            value: '字典值',
            other: '其他',
            status: '状态',
            status_normal: '正常',
            status_delete: '作废',
            op_note: '请点击左边字典项进行操作',
            valid_title: '请输入字典标题',
            valid_code: '唯一代码重复！',
            notfound: '未找到字典！',
            save_confirm: '您确定要保存',
            save_confirm1: '为下级',
            save_confirm2: '吗？',
            delete_select: '请选择要删除的字典！',
            delete_confirm: '此操作将会删除该字典及其所有下级字典，您确定要删除吗？',
            delete_notfound: '未找到要删除的字典！',
            sort_not: '当前字典没有下级字典，勿需排序！',
            sort_onenot: '当前字典只有一个下级字典，勿需排序！',
            export_select: '您没有选择要导出的字典！',
            import_file: '要导入的文件必须是json格式文件!',
        },
        //首页设置
        homeset: {
            name: '名称',
            title: '标题',
            title_note: '模块名称或标题',
            type_top: '顶部统计',
            type_left: '左边模块',
            type_right: '右边模块',
            source: '数据来源',
            source_vue: 'VUE组件',
            source_dbconn: '数据连接',
            source_source: '来源',
            source_source_note: 'SQL或VUE组件名称',
            use: '使用对象',
            use_note: '为空表示所有人可使用',
            link: '链接地址',
            color: '颜色',
            valid_name: '请输入名称',
            valid_title: '请输入显示标题',
            valid_type: '请选择类型',
            valid_source: '请选择数据来源',
            valid_source1: '请输入来源',
            delete_noselect: '您没有选择要删除的首页设置！',
            delete_confirm: '您确定要删除所选首页设置吗？',
            todo: '待办事项',
        },
        //工作日设置
        weekday: {
            year: '年份',
            days: '共有工作日',
            days1: '天',
            save: '保存设置',
            tips: '提示：点击单元格背景即设置为节假日',
            month: '月',
            Monday: '一',
            Tuesday: '二',
            Wednesday: '三',
            Thursday: '四',
            Friday: '五',
            Saturday: '六',
            Sunday: '日',
        },
        //序列号
        number: {
            title: '标题',
            format: '格式',
            format_note: '',
            digit: '位数',
            current: '当前值',
            type: '类型',
            type0: '不重置',
            type1: '年流水',
            type2: '月流水',
            type3: '日流水',
            valid_title: '请输入标题',
            valid_digit: '请输入位数',
            valid_type: '请选择类型',
            delete_noselect: '请选择要删除的流水号！',
            delete_confirm: '您确定要删除所选流水号吗？',
        },
        //图标
        icon: {
            total: '共',
            total1: '个图标，输入图标名称可搜索。',
            copied: '已复制到剪贴板！',
        },
        //日志查询
        log: {
            title: '标题',
            type: '类型',
            date: '日期',
            operator: '操作人',
            details: '详情',
            view_details: '查看日志详情',
            ip: '发生ip',
            url: '来源URL',
            url1: '操作URL',
            browse: '浏览器',
            contents: '日志内容',
            before: '操作前',
            after: '操作后',
            other: '其他内容',
            type_login: '登录日志',
            type_system: '系统管理',
            type_flowmanage: '流程管理',
            type_flowrun: '流程运行',
            type_formmanage: '表单管理',
            type_other: '其他类型',
            type_error: '系统异常',
            notfound: '未找到日志！',
        },
        //调查问卷
        quest: {
            subject: '主题',
            start_time: '开始时间',
            end_tiem: '结束时间',
            no_end_time: '无结束时间',
            publisher: '发布人',
            submit: '提交',
            add_subject: '添加选题',
            publish: '发布时间',
            publish1: '发布',
            submit_type: '提交方式',
            anonymous: '匿名',
            submit_confirm: '您确定要提交吗？',
            submit_success: '提交成功！',
            submit_notfound: '没有找到要提交的问卷！',
            submit_expire: '问卷已超过结束时间，不能提交！',
            submit_fail: '提交失败！',
            create_time: '创建时间',
            participants: '参与人员',
            result_query_user: '结果查询人员',
            status: '状态',
            status_editing: '编辑中',
            status_published: '已发布',
            status_result: '已有结果',
            status_completed: '已完成',
            status_unknown: '未知',
            topic: '选题',
            end_tiem_note: '为空表示无结束时间',
            valid_subject: '主题不能为空',
            valid_participants: '参与人员不能为空',
            notfound: '未找到问卷信息！',
            loadfail: '获取问卷信息失败！',
            delete_confirm: '此操作将删除该调查问卷所有数据，并且不可恢复，您确定要删除吗？',
            delete_result: '问卷已经有提交结果，不能删除！',
            operation_confirm: '您确定要',
            operation_confirm1: '吗？',
            success: '成功！',
            fail: '失败！',
            publish_nosubject: '问卷没有选题，不能发布！',
            cancel_result: '问卷已经有提交结果，不能取消！',
            topic_title: '选题标题',
            topic_selecttype: '选择方式',
            topic_selecttype0: '单选',
            topic_selecttype1: '多选',
            topic_selecttype2: '文本框',
            topic_selecttype3: '文本域',
            topic_style: '样式',
            topic_style1: '选题样式',
            topic_input: '输入',
            topic_input_no: '无',
            topic_valid_title: '请输入选题标题',
            topic_loaderror: '加载选题错误！',
            result_subject: '按选题查看',
            result_user: '按人员查看',
            result_view: '查看',
            result_view_noauth: '您没有权限查看问卷结果',
            submit_time: '提交时间',
            submit_no: '未提交',
        },
        //文件管理 
        files: {
            upload: '上传文件',
            newfolder: '新建文件夹',
            rename: '重命名',
            move: '移动',
            share: '分享',
            delete: '删除',
            search_note: '输入关键字搜索',
            search: '搜索',
            name: '名称',
            modifydate: '修改日期',
            type: '类型',
            folder: '文件夹',
            file: '文件',
            size: '大小',
            selectmoveto: '选择要移动到的文件夹',
            fileshare: '文件分享',
            receiver: '接收人员',
            expiretime: '过期时间',
            expiretime_note: '为空表示不过期',
            inputfoldername: '请输入文件夹名称',
            foldernameempty: '文件夹名称不能为空！',
            folder_empty: '目录名称为空！',
            folder_auth: '权限错误！',
            folder_exists: '目录已经存在！',
            rename_select: '请选择要重命名的文件或文件夹！',
            rename_inputname: '请输入新的名称',
            rename_valid: '名称不能为空！',
            rename_fileemtpy: '文件或文件夹为空！',
            rename_empty: '名称为空！',
            rename_nouser: '没有登录！',
            move_select: '请选择要移动的文件或文件夹！',
            move_selectto: '请选择要移动到的文件夹！',
            move_empty: '要移动到的文件或文件夹为空！',
            delete_select: '请选择要删除的文件或文件夹！',
            delete_confirm: '您确定要删除吗？',
            share_selectreceiver: '请选择接收人',
            share_folder: '文件夹不能分享！',
            share_select: '请选择要分享的文件！',
            share_success: '分享成功！',
            share_fail: '分享失败！',
            share_cancel: '取消分享',
            share_time: '分享时间',
            share_long: '永久有效',
            share_isview: '查看',
            share_isview_yes: '是',
            share_isview_no: '否',
            share_cancel_select: '请选择要取消分享的文件！',
            share_cancel_confirm: '您确定要取消分享吗？',
            share_cancel_success: '取消分享成功！',
            share_cancel_fail: '取消分享失败！',
            share_user: '分享人',
        },
        //内部邮箱
        mail: {
            receiver: '收件人',
            copyfor: '抄送',
            secret: '密送',
            subject: '主题',
            color: '颜色',
            attachment: '附件',
            contents: '正文',
            savedraft: '存草稿',
            send: '发送',
            send_valid_receiver: '请选择收件人',
            send_valid_subject: '邮件主题不能为空',
            reply: '回复',
            redirect: '转发',
            edit: '编辑',
            readlist: '阅读情况',
            oldcontents: '原始邮件',
            sender: '发件人',
            date: '日期',
            send_confirm: '您确定要发送吗？',
            valid_receiver: '接收人不能为空！',
            valid_subject: '邮件主题不能为空！',
            success: '成功！',
            fail: '失败！',
            source: '来源',
            outbox: '发件箱',
            inbox: '收件箱',
            draftbox: '草稿箱',
            delete1_select: '请选择要彻底删除的邮件！',
            delete1_confirm: '您确定要彻底删除所选邮件吗？',
            delete_success: '删除成功！',
            delete_fail: '删除失败！',
            delete: '删除',
            deletedraft_select: '请选择要删除的草稿邮件！',
            areyousure: '您确定要',
            thorough: '彻底',
            deletedraft_confirm: '删除所选草稿邮件吗？',
            unread: '未读',
            delete_select: '请选择要删除的邮件！',
            delete_confirm: '删除所选邮件吗？',
            delete_confirm1: '删除邮件吗？',
            view_notfound: '要查看的邮件不存在，请刷新列表！',
            view_noauth: '您无权查看该邮件！',
        },
    },
    //文档中心
    doc: {
        doc: '文档中心',
        more: '更多',
        unread: '未读',
        title: '标题',
        date: '日期',
        dir: '栏目',
        dir_edit: '编辑栏目',
        dir_add: '添加子栏目',
        dir_name: '栏目名称',
        dir_name_traditional: '繁体名称',
        dir_name_english: '英语名称',
        dir_manager: '管理人员',
        dir_manager_note: '可以修改栏目和添加下级子栏目',
        dir_publisher_note: '可以发布和修改该栏目文档',
        dir_reader: '阅读人员',
        dir_reader_note: '可以阅读查看该栏目文档，为空表示所有人员都可阅读。',
        dir_notfound: '未找到栏目！',
        dir_valid_name: '请输入栏目名称',
        dir_valid_manager: '请选择管理人员',
        dir_valid_publisher: '请选择发布人员',
        dir_valid_edit: '您没有栏目编辑权限！',
        dir_valid_add: '您没有添加子栏目权限！',
        dir_edit_noselect: '请选择要编辑的栏目！',
        dir_delete_confirm: '您确定要删除该栏目吗？',
        dir_delete_valid: '没有栏目删除权限！',
        dir_delete_hasdoc: '该栏目下有文档，不能删除！',
        dir_delete_root: '不能删除根栏目！',
        doc_publisher: '发布人',
        doc_publishtime: '发布时间',
        doc_level: '文档等级',
        doc_normal: '普通',
        doc_attachment: '附件',
        doc_source: '来源',
        doc_contents: '内容',
        doc_valid_title: '请输入文档标题',
        doc_valid: '文档栏目，标题，内容不能为空！',
        doc_valid_publish: '您没有栏目发布文档权限！',
        doc_valid_show: '您无权查看该文档！',
        doc_loadfail: '加载文档数据失败！',
        doc_delete_noselect: '请选择要删除的文档！',
        doc_delete_confirm: '您确定要删除文档吗？',
        doc_delete_valid: '没有权限删除文档！',
        doc_notfound: '未找到文档！',
    },
    //应用程序设计
    program: {
        name: '应用名称',
        name1: '名称',
        type: '应用分类',
        type1: '分类',
        delete_noselect: '您没有选择要删除的应用！',
        delete_confirm: '您确定要删除所选应用吗？',
        delete1_noselect: '您没有选择要彻底删除的应用！',
        delete1_confirm: '您确定要彻底删除所选应用吗？',
        dbconn: '数据连接',
        editform: '编辑表单',
        selectapptype: '选择应用分类',
        win_width: '窗口宽度',
        win_height: '窗口高度',
        button_Location: '按钮位置',
        button_Location_row: '新行',
        button_Location_query: '查询后面',
        select_row: '选择列',
        select_row_no: '无',
        select_row_radio: '单选',
        select_row_checkbox: '多选',
        pager: '分页',
        pager_no: '不分页',
        pager_yes: '分页',
        table_width: '表格宽度',
        table_height: '表格高度',
        table_size: '表格大小',
        table_border: '边框',
        table_column: '列设置',
        table_column_note: 'el-table-column prop=name label=列名',
        scripts: '脚本',
        scripts_note: 'mounted中加载数据后执行',
        events: '事件',
        events_note: '添加修改删除数据时执行的方法',
        sort: '默认排序',
        sort_note: '初次打开列表时的排序',
        attr_valid_name: '请输入应用名称',
        attr_valid_type: '请选择分类',
        attr_valid_conn: '请选择数据连接',
        attr_valid_sql: '请输入SQL',
        load_fail: '加载数据失败！',
        button_auth: '权限验证',
        button_auth_no: '不验证',
        button_auth_yes: '验证',
        button_select: '选择按钮',
        button_showtype: '显示类型',
        button_showtype_normal: '常规按钮',
        button_showtype_list: '列表按钮',
        button_showif: '显示判断',
        button_showif_note: '判断按钮是否显示的js脚本',
        publish: '发布',
        publishing: '正在发布…',
        publish_success: '发布成功！',
        publish_notfound: '未找到要发布的应用！',
        publish_fail: '发布失败！',
        return: '返回',
        attr: '属性',
        list: '列表',
        button: '按钮',
        validate: '验证',
        field: '字段',
        field_name: '字段名称',
        field_showname: '显示名称',
        field_showtype: '显示方式',
        align: '对齐方式',
        export_copy: '复制列表字段',
        export_copy_confirm: '复制将清除现有导出字段设置，确定要复制吗？',
        export_copy_success: '复制成功！',
        export_copy_iderror: '参数错误！',
        export_copy_nofield: '没有要复制的列表字段！',
        export_datatype: '数据类型',
        export_format: '格式',
        export_delete_noselect: '您没有选择要删除的导出字段！',
        export_delete_confirm: '您确定要删除所选导出字段吗？',
        custom: '自定义',
        custom_note: '自定义字符串',
        width: '宽度',
        list_isshow: '隐藏',
        list_issort: '排序',
        list_forze: '冻结列',
        list_forze_no: '无',
        list_forze_left: '左',
        list_forze_right: '右',
        list_showstyle: '显示样式',
        list_showstyle_note: 'css样式',
        list_onlybuttoncolumn: '一个应用只能添加一个按钮列！',
        list_delete_noselect: '您没有选择要删除的列表！',
        list_delete_confirm: '您确定要删除所选列表吗？',
        operator: '操作符',
        control_name: '控件名称',
        input_type: '输入类型',
        placeholder: '背景文字',
        datasource: '数据来源',
        source: '来源',
        source_note: '字符串表达式/SQL语句/URL地址',
        source_dict: '数据字典',
        valuefield: '值字段',
        select_range: '选择范围',
        select_type: '选择类型',
        select_size: '选择个数',
        query_numberid: '查询时转数字id',
        string_expression: '字符串表达式',
        all: '全部',
        query_delete_noselect: '您没有选择要删除的查询！',
        query_delete_confirm: '您确定要删除所选查询吗？',
        valid_all_status: '将字段状态设置为',
        valid_all_check: '将字段检查设置为',
        table_name: '表名',
        field_note: '字段说明',
        field_status: '字段状态',
        valid_type: '验证类型',
        status_edit: '编辑',
        status_readonly: '只读',
        status_hidden: '隐藏',
        check_check: '检查',
        check_notcheck: '不检查',
        check_notfoundapp: '未找到应用程序设计！',
        recovery_confirm: '您确定要恢复该应用吗？',
        run_delete_noselect: '您没有选择要删除的数据！',
        run_delete_confirm: '您确定要删除所选数据吗？',
        run_delete_noform: '应用未设置表单！',
        rownumber: '序号',
        export_headtext: '导出标题',
        export_headtext_note: '导出Excel文件的标题',
        export_filename: '文件名称',
        export_filename_note: '导出Excel文件的文件名',
        export_template: '模板文件',
        import_table: '导入表',
        import_table_note: '导入Excel文件对应的数据表名',
        import_titlefield: '标识列',
        import_titlefield_note: '标识单次导入的标识字段',
        import_filetypeerr: '要导入的文件必须是excel格式文件!',
        import_dialogtitle: '导入',
        import_dialogselectfile: '选择文件',
        import_notfoundmodel: '未找到程序运行时！',
        import_nosettable: '未设置要导入的表！',
        import_nosetdbconn: '未设置数据连接！',
        import_error: '导入发生错误！请联系管理员！',
    },
}