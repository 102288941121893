﻿import { createI18n } from 'vue-i18n';
import utils from '../roadui-assets/utils';
import zh_cn from './zh-cn';
import zh_tw from './zh-tw';
import en from './en';
const i18n = createI18n({
    //fallbackLocale: 'zh-cn',
    globalInjection: true,
    legacy: false, //you must specify 'legacy: false' option
    locale: utils.getLang(),
    messages: {
        'zh-cn': zh_cn,
        'zh-tw': zh_tw,
        'en': en,
    }
});
export default i18n;