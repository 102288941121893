﻿export default {
    //公用部分
    common: {
        confirm: 'Confirm',
        tips: 'Tips',
        ok: 'OK',
        cancel: 'Cancel',
        parttime: 'part-time',
        this_week: 'This week',
        this_month: 'This month',
        last_three_months: 'Three months',
        but_primary: 'Primary',
        but_default: 'Default',
        but_success: 'Success',
        but_warning: 'Warning',
        but_danger: 'Danger',
        but_info: 'Info',
        but_text: 'Text',
        note: 'Note',
        add: 'Add',
        delete: 'Delete',
        edit: 'Edit',
        operation: 'Handle',
        save: 'Save',
        save_child: 'Save as child',
        close: 'Close',
        clear: 'Clear',
        sort: 'Sort',
        child_sort: 'Child sort',
        export: 'Export',
        import: 'Import',
        select_file: 'Select file',
        upload_fail: 'File upload failed!',
        import_success: 'Import successfully!',
        import_notexists: 'The file to be imported does not exist!',
        import_notjson: 'The file to be imported is not a valid json format file!',
        import_fail: 'Import failed!',
        print: 'Print',
        return: 'Return',
    },
    //组件
    components: {
        //组织架构选择组件
        org: {
            org: 'Org',
            parttime: 'Part-time',
            workgroup: 'Workgroup',
            search: 'Search',
            search_tip: 'Search Keyword',
            search_result: 'No results were found for \'\'',
            search_result1: '\'\'',
            search_result2: 'Search results for\'\'',
            search_result3: '\'\'',
            select_size: 'At most ',
            select_size1: ' item can be selected for current settings!',
        },
        //附件
        file: {
            upload: 'Upload',
            delete_confirm: 'Are you sure?',
            total_zero: '0 files in total',
            valid_type: 'File type not allowed to upload!',
            upload_fail: 'File upload failed!',
            valid_size: 'The current setting can only add ',
            valid_size1: ' file at most!',
            delete_success: 'File deleted successfully!',
            delete_fail: 'File deletion failed!',
            delete_unknown: 'Unknown error!',
            valid_paramempty: 'Parameter is null!',
            valid_notfound: 'File does not exist!',
            total: '',
            total1: ' files in total',
        },
    },
    //登录页面
    login: {
        account: 'Account',
        password: 'Password',
        lang: 'Language',
        rememberme: 'Remember',
        none: 'No',
        oneday: 'A day',
        aweek: 'A week',
        login: 'Login',
        validate_account: 'Please enter your account',
        validate_password: 'Please enter the password',
        msg_force: 'Your account is already in',
        msg_force1: 'Login,do you want to log in forcibly?',
        msg_empty: 'Account or password cannot be empty!',
        msg_error: 'Account or password error!',
        msg_frozen: 'User is frozen!',
        msg_frozen1: 'User\'s organization ',
        msg_frozen2: ' frozen!',
        msg_notfoundorg: 'Your organization was not found!',
        msg_fail: 'Login failed!',
    },
    //首页
    index: {
        set_menucolor: 'Menu color',
        set_menucolor_light: 'Light',
        set_menucolor_drak: 'Drak',
        set_themecolor: 'Theme color',
        set_navcolor: 'Navigation color',
        set_themecolor_theme: 'Theme',
        set_themecolor_white: 'White',
        set_hometype: 'Home layout',
        logout: 'Logout',
        download: 'Download',
        todoitem: 'Todo',
        message: 'Message',
        from: ' - from',
        logout_confirm: 'Are you sure you want to exit the system?',
        loading: 'Loading…',
        load_error: 'Load error!',
        refresh: 'Refresh the current page',
        menu_expand: 'Expand menu',
        menu_colse: 'Close menu',
        ctxmenu_refresh: 'Refresh',
        ctxmenu_closecurrent: 'Close current',
        ctxmenu_closeother: 'Close Others',
        ctxmenu_closeall: 'Close all',
    },
    //流程处理
    flow_handle: {
        //公共
        common: {
            status_normal: 'Normal',
            status_overdue: 'Overdue soon',
            status_expired: 'Expired',
            status_serious: 'Serious overdue',
            comment_common: 'Opinions',
            comment_comment: 'Comment',
            handle_type: 'Handle type',
            flow_status: 'Status',
            flow_status_ing: 'Inprocess',
            flow_status_end: 'Completed',
            content: 'Contents',
            flow_chat: 'Chart',
            task_type: 'Task type',
            task_type_normal: 'Normal',
            task_type_assign: 'Assign',
            task_type_entrust: 'Entrust',
            task_type_redirect: 'Redirect',
            task_type_back: 'Back',
            task_type_copyfor: 'Copy for',
            task_type_beforeaddwrite: 'Before sign',
            task_type_afteraddwrite: 'After sign',
            task_type_addwrite: 'Sign',
            task_type_goto: 'Goto',
            task_type_solicitingopinions: 'Soliciting opinions',
            task_handletype_wait: 'Wait',
            task_handletype_ing: 'Inprocess',
            task_handletype_completed: 'Completed',
            task_handletype_otherhandle: 'Handled by others',
            task_handletype_backed: 'Backed',
            task_handletype_otherbacked: 'Backed by others',
            task_handletype_redirect: 'Redirected',
            task_handletype_delegated: 'Entrusted',
            task_handletype_read: 'Read',
            task_handletype_assigned: 'Assigned',
            task_handletype_jumped: 'Jumped',
            task_handletype_terminated: 'Terminated',
            task_handletype_otherterminated: 'Terminated by others',
            task_handletype_suspended: 'Suspended',
            task_handletype_othersuspended: 'Suspended by others',
            task_handletype_signed: 'Signed',
            task_handletype_autoComplete: 'Auto complete',
            task_handletype_replied: 'Replied',
        },
        //待办页面
        todo: {
            title: 'Title',
            flow: 'Flow',
            receive_time: 'Receive time',
            completed_time: 'Completed time',
            init_time: 'Start time',
            start_time: 'Start',
            end_time: 'End',
            query: 'Query',
            step: 'Step',
            sender: 'Sender',
            receiver: 'Receiver',
            handler: 'Handler',
            status: 'Status',
            complete_time: 'Required completion time ',
            no_complete_time: 'No required completion time',
            handle: 'Handle',
            batch_handle: 'Batch handle',
            process: 'Process',
            form: 'Form',
            urge: 'Urge',
            withdraw: 'W/D',
            delete: 'Delete',
            delete_confirm: 'Are you sure you want to delete?',
            delete_success: 'Deletion succeeded!',
            delete_error: 'An error occurred during deletion!',
            handle_pass: 'Pass',
            handle_back: 'Back',
            handle_noselect: 'Please select tasks!',
            handle_comment: 'Please enter comments',
            handle_selecttype: 'Please select type',
            handle_success: 'Batch processing succeeded ',
            handle_success1: ' tasks!',
            handle_error: 'Error occurred in batch processing!',
            withdraw_confirm: 'Are you sure?',
            withdraw_success: 'Operation succeeded!',
            withdraw_handled: 'The follow-up task has been processed and cannot be withdrawn!',
            withdraw_error: 'An error occurred while executing the recall!',
            urge_type: 'Type',
            urge_msg_receiver: 'Please select the receiver',
            urge_msg_type: 'Please select type',
            urge_msg_content: 'Please enter the message content',
            urge_success: 'successful!',
            urge_notfounduser: 'Current user information not found!',
            urge_notfoundtask: 'Task not found!',
            process_list: 'List',
            process_completed: 'Completed',
            process_ing: 'In process',
            process_notarrived: 'Not arrived',
            process_loadflowchartfail: 'Load fail!',
            entrust_time: 'Entrust time',
            entrust_withdraw: 'Recall',
            entrust_withdraw_confirm: 'Are you sure you want to recall this task?',
            entrust_withdraw_success: 'Recall succeeded. Please check the task in the to-do list!',
            entrust_withdraw_handled: 'This task has been processed and cannot be recalled!',
            entrust_withdraw_fail: 'Recall failed!',
            start_useriderror: 'User ID error!',
            start_flowloadfail: 'Flow loading failed!',
            print_commnets: 'Print comments',
            receive: 'Receive',
            current: 'Current',
            manage: 'Manage',
            instance_manage: 'Instance manage',
            edit_status: 'Modify status',
            edit_status_valid: 'Please select a status',
            edit_status_success: 'ask status modified successfully!',
            edit_status_fail: 'Error occurred in modifying process task status!',
            assign_success: 'Assignment successfully!',
            assign_fail: 'Assignment failed!',
            goto_noselect: 'You have not selected the step!',
            goto_success: 'Goto successfully!',
            goto_paramserror: 'Parameter error!',
            goto_fail: 'Goto fail!',
            del_confirm: 'Are you sure you want to delete this task?',
        },
        //流程运行
        run: {
            inputcomments: 'Please enter comments',
            sign: 'Sign',
            signure: 'Please sign!',
            inputsignpwd: 'Enter the signature password',
            receive: 'Receive',
            notfoundflowrun: 'Flow runtime not found!',
            notfoundsetprun: 'Step runtime not found!',
            handleing: 'The current task is being processed by',
            handleing1: ',please wait!',
            notauthview: 'You are not authorized to view the current task!',
            cannotstart: 'Cannot initiate a new instance if the current flow is',
            cannotstart1: '',
            notfounduser: 'User not found!',
            notfoundtask: 'Current task not found!',
            loaderror: 'Load error!',
            cannotempty: 'Cannot be empty',
            on: 'On',
            off: 'Off',
            saved: 'Saved!',
            sendto: 'Send to: ',
            completedwait: 'Completed,waiting for others to handle!',
            sendwait: 'Sent,waiting for others to handle!',
            completedwaitstep: 'Completed,waiting for other steps!',
            sendwaitstep: 'Sent,waiting for other steps!',
            completed: 'Completed!',
            sended: 'Sent!',
            backto: 'Back to: ',
            backwait: 'Backed,waiting for others to handle!',
            backed: 'Backed！',
            postponed: 'Postponed!',
            ended: 'Ended!',
            copyfored: 'Copy for to: ',
            copyfored1: 'Sent!',
            solicitopinion: 'Comments have been solicited from: ',
            solicitopinion1: '',
            solicitopinioned: 'Comments solicited!',
            replyed: 'Replied!',
            redirect1: 'Redirect to: ',
            redirected: 'Redirected!',
            addwrite1: ' add signature!',
            addwrited: 'Signed!',
            withdrawed: 'Withdrew!',
            cannotwithdrawed: 'The task has been processed and cannot be withdrawn!',
            handlesuccess: 'Processing successfully!',
            paramerror: 'Parameter error!',
            flowiderror: 'Flow id error!',
            knownhandletype: 'Unknown processing type!',
            cannothandletask: 'You cannot process this task!',
            taskhandled: 'The current task has been processed!',
            handlerempty: 'Handler is empty!',
            cannotstarttask: 'The current flow cannot initiate a new task!',
            grouptaskempty: 'Current instance group task is empty!',
            savedataerror: 'An error occurred while saving the form data!',
            startsubflorerror: 'Error occurred in initiating subflow!',
            subflownocompleted: 'The task corresponding to the subflow has not been completed and cannot be submitted!',
            notfoundcopyfotask: 'No steps found to copy for!',
            copyreceiverempty: 'Receiver is empty!',
            notfoundoptionstep: 'No steps found!',
            optionreceiverempty: 'Receiver is empty!',
            notfoundredirectstep: 'No steps found!',
            redirectreceiverempty: 'Receiver is empty!',
            notfoundaddwritestep: 'No steps found!',
            addwritereceiverempty: 'Receiver is empty!',
            cannotedeleteinstances: 'Flow instance cannot be voided if it is not the initiator!',
            deleteinstanceerror: 'Error occurred in voiding flow instance!',
            cannotsetback: 'The current step cannot be back!',
            backnotreceover: 'No receiver found after back!',
            handlerflowerror: 'An error occurred in the processing flow!',
            confirmsendstep: 'Confirm receive steps',
            confirmbakcstep: 'Confirm back steps',
            process: 'Process',
            flowchart: 'Flow chart',
            savesethandler: 'Please save first!',
            sethandler: 'Specify step handler',
            taskurge: 'Urge',
            withdrawconfirm: 'Are you sure?',
            postponeconfirm: 'Are you sure?',
            endconfirm: 'Are you sure?',
            copyfor: 'Copy for',
            solicitopinions: 'Soliciting opinions',
            inputopinions: 'Please enter comments!',
            redirect: 'Redirect',
            addwrite: 'Add signure',
            selectaddwritereceiver: 'Select receiver',
            stepinsend: 'Send in step',
            notfoundstep: 'No steps found!',
            notselectreceiver: 'No receiver selected!',
            noselectbackstep: 'You have not selected a step to back!',
            loadchartfail: 'Failed to load flow chart!',
            selectcompletedtime: 'Select time',
            sendnotnextstep: 'The following steps are unqualified!',
            notselectcompletedtime: 'No completion time selected!',
            notselectsendstep: 'You have not selected a receive step!',
            selecthandler: 'Select handler',
            sethandlersuccess: 'Setting successfully!',
            sethandlerfail: 'Setting fail!',
            sign_password: 'Password',
            sing_password_valid: 'Please input a password',
            sing_passwordempty: 'Password cannot be empty!',
            sing_passworderror: 'Password error!',
            visibility: 'Visibility',
            visibility0: 'Only yourself',
            visibility1: 'Participants',
            visibility_valid: 'Please select visibility',
            note: 'Explain',
            solicitopinion_note: 'The receiver can see the explains',
            view_reply: 'View reply',
            reply_time: 'Reply time',
            reply_not: 'No reply',
            solicitopinion_reply: 'Reply',
            form_valid_error: 'Form validation error, please check the required items of the form!',
            completedconfirm: 'Are you sure you want to complete?',
        },
    },
    //流程设计
    flow_design: {
        status: {
            unknown: 'Unknown',
            indesign: 'In design',
            published: 'Published',
            uninstall: 'Uninstall',
            deleted: 'Deleted',
        },
        //委托
        entrust: {
            user: 'User',
            receiver: 'Receiver',
            edit_time: 'Edit time',
            all_flow: 'All flows',
            all_note: 'Null means all',
            valid_receiver: 'Please select the recipient',
            valid_starttime: 'Please select the start time',
            valid_endtime: 'Please select the end time',
            status_nostart: 'Not started',
            status_ing: 'Entrusting',
            status_end: 'End',
            status_unknown: 'Unknown',
            load_fail: 'Failed to get data!',
            save_success: 'Save successfully!',
            save_fail: 'Save failed!',
            datavalid_error: 'Data validation error!',
            delete_noselect: 'You have not selected data to delete!',
            delete_confirm: 'Are you sure you want to delete?',
            delete_success: 'Successfully deleted!',
            delete_fail: 'Delete failed!',
        },
        //意见
        comments: {
            use: 'User',
            comments: 'Comments',
            all_user: 'All user',
            source: 'Source',
            user_add: 'User add',
            admin_add: 'Admin add',
            sort: 'Sort',
            edit_note: 'Users cannot be modified during editing',
            validate_comment: 'Please enter comments',
            delete_noselect: 'You have not selected any comments to delete!',
        },
        //接口系统
        apisystem: {
            name: 'Name',
            identification: 'ID',
            key: 'Key',
            update: 'Update',
            valid_name: 'Please enter the system name',
            valid_ident: 'Please enter the system ID',
            valid_key: 'Please enter the call Key',
            delete_noselect: 'You have not selected an interface system to delete!',
            delete_confirm: 'Are you sure you want to delete the selected interface system?',
        },
        //流程设计
        design: {
            hasdesign: 'There is currently a flow under design. Please close the flow under design before opening it!',
            new: 'New flow',
            new1: 'New',
            design: 'Design',
            delete_noselect: 'You have not selected a flow to delete!',
            delete_confirm: 'Are you sure you want to delete the selected flow?',
            delete1: 'Thorough delete',
            delete1_noselect: 'You have not selected a flow to delete!',
            delete1_confirm: 'Are you sure you want to delete the selected flow?',
            delete1_success: 'Thorough delete successfully!',
            delete1_hastask: 'The instance task already exists, and cannot be deleted!',
            delete1_fail: 'Thorough delete fail!',
            recovery: 'Recovery',
            recovery_confirm: 'Are you sure you want to resume the flow?',
            recovery_success: 'Recovery successfully!',
            recovery_fail: 'Recovery fail!',
            export_noselect: 'You have not selected a flow to export!',
            name: 'Flow name',
            step: 'Step',
            type: 'Type',
            system: 'Api sys',
            manager: 'Manager',
            instancemanager: 'Instance',
            debug: 'Debug',
            debug_user: 'User',
            icon: 'Icon',
            color: 'Color',
            color_note: 'Icon and color display on the start page',
            dbconn: 'Data connection',
            dbtable: 'Table',
            tableprimary: 'Primary key',
            idevent: 'Identify events',
            idfield: 'Identify field',
            idvalue: 'Identify value',
            event_completed: 'On Completed',
            event_completed_note: '',
            event_postpone: 'On postpone',
            event_postpone_note: '',
            event_end: 'On end',
            event_end_note: '',
            event_delete: 'On deleted',
            event_delete_note: '',
            subflowstep: 'Subflow step',
            create_time: 'Create time',
            create_user: 'Creater',
            attr: 'Attr',
            subflow: 'Subflow',
            line: 'Line',
            brokenline: 'Broken line',
            copy: 'Copy',
            copy_noselect: 'Please select the steps to copy!',
            link_noselect: 'Please select the steps to connect!',
            saveas: 'Save as',
            saveas_success: 'Save successfully!',
            saveas_fail: 'Save fail!',
            publish: 'Publish',
            uninstall: 'Uninstall',
            valid_name: 'Please enter a new flow name',
            valid_name1: 'Flow name cannot be empty, please set it in the attribute!',
            valid_type: 'Flow type cannot be empty, please set it in the attribute!',
            valid_step: 'The flow requires at least one step!',
            notfound: 'Not found flow!',
            notauth: 'You do not have permission to manage this flow!',
            openfail: 'Open fail!',
            noflowtips: 'Please create or open a flow first!',
            iduseriderror: 'Wrong flow id or flow name or user id!',
            ing: 'In process of ',
            success: ' successfully!',
            fail: ' fail!',
            jsonerror: 'Json parse error!',
            flowiderror: 'Flow id error！',
            circulation_conditions: 'Conditions',
            deleteobj_noselect: 'Please select the object to delete!',
            deleteobj_confirm: 'Are you sure you want to delete the selected object?',
            line_data: 'Data conditions',
            line_dataexpression: 'Data condition expression',
            line_and: 'And',
            line_or: 'Or',
            line_text: 'Label',
            line_pdtype: 'Judgment mode',
            line_text_note: 'Label displayed on the line',
            line_org: 'Organization',
            line_orgexpression: 'Organization expression',
            line_orgselect: 'Select org',
            line_sender: 'Sender',
            line_initiator: 'Initiator',
            line_in: 'In',
            line_notin: 'Not in',
            line_method: 'Custom method',
            line_method1: 'Method',
            line_note1: '1.Example:RProcess.Web.Controllers.TestController.TestLineCondition',
            line_note2: '2.The condition is valid when the method returns true or "true" or "1"',
            line_sql: 'Sql query',
            line_sql_note1: '1.The returned result of Sql query indicates that the condition is valid',
            line_sql_note2: '2.Sql should only return one row and one column of data',
            line_conditions_dy: '=',
            line_conditions_bdy: '!=',
            line_conditions_day: '>',
            line_conditions_dydy: '>=',
            line_conditions_xy: '<',
            line_conditions_xydy: '<=',
            line_conditions_like: 'Like',
            line_conditions_notlike: 'Not like',
            line_conditions_subtablesumdy: 'Subtable sum =',
            line_conditions_subtablesumbdy: 'Subtable sum !=',
            line_conditions_subtablesumday: 'Subtable sum >',
            line_conditions_subtablesumdydy: 'Subtable sum >=',
            line_conditions_subtablesumxy: 'Subtable sum <',
            line_conditions_subtablesumxydy: 'Subtable sum <=',
        },
        //流程设计步骤基本
        step_base: {
            base: 'Base',
            stepname: 'Step name',
            showcomment: 'Comments',
            commentfile: 'Attachment',
            commentfile_note: 'Whether attachments can be uploaded when filling in handling comments',
            autoconfirm: 'Direct send',
            autoconfirm_note: 'The next step is to send directly when there is a default handler, and the send confirmation window does not pop up.',
            worktime: 'Work Days',
            sendsettiem: 'Specify time when sending',
            signtype: 'Sign type',
            signtype1: 'No comments',
            signtype2: 'Comments - No signature',
            signtype3: 'Comments - Signature',
            signtype4: 'Comments - Password signature',
            expire: 'T remind',
            expireday: 'Advance',//提前
            expireday1: '',
            expire_interval: 'Every ',
            archive: 'Archive',
            batch: 'Batch',
            batch_note: 'Set to enable this step task to be displayed in the batch list',
            concurrent: 'Concurrency',
            concurrent_note: 'Whether there can be only one person at the same time when the step is processed by more than one person.',
            expirehandle: 'T handler',
            expirehandle1: 'Do not handle',
            expirehandle2: 'Auto submit',
            sendmsg: 'Send msg',
            backmsg: 'Back msg',
            form: 'Form',
            mobileform: 'Mobile',
        },
        //流程设计步骤策略
        step_behavior: {
            behavior: 'Strategy',
            flowtype: 'Flow type',
            flowtype1: 'Radio',
            flowtype2: 'Checkbox - First selected by default',
            flowtype3: 'Checkbox - All selected by default',
            flowtype4: 'Checkbox - All selected by default, can\'t cancel',
            flowtype5: 'Condition - Prompt when there is no next step',
            flowtype6: 'Condition - Complete when there is no next step',
            selecttype: 'Select type',
            selecttype_org: 'Org select type',
            selecttype_org_unit: 'Company',
            selecttype_org_dept: 'Dept',
            selecttype_org_station: 'Station',
            selecttype_org_user: 'User',
            selecttype_org_wg: 'Workgroup',
            runselect: 'Allow',
            runselect_note: 'Allow the sender to select the receiver of this step during processing',
            selectquantity: 'Qty',
            selectquantity_note: 'The number of organization personnel allowed to be selected when sending. 0 or blank indicates no limit',
            handlertype: 'Handler typ',
            selectrange: 'Sel range',
            selectrange1: 'Default handler selected',
            selectrange2: 'Default handler is not selected',
            selectrangeset: 'Specify select range',
            handlersetp: 'Handle step',
            handlersetp_note: 'Select handler step',
            addwriterange: 'Sign range',
            addwriterange_note: 'Specify the signature selection range',
            defaulthandler: 'Default',
            defaulthandler_note: 'Use the default handler of this step as the signature select range',
            valuefield: 'Value field',
            lasthandler: 'Last',
            lasthandler_note: 'If the default handler is not found, the last handler of the read step is the default handler.',
            defaultsql: 'Sql or',
            defaultsql1: 'Method',
            defaultsql_note: 'The string beginning with [sql] is expressed as sql',
            defaulthandler1: 'Default',
            defaultset: 'Specify default handler',
            handlestrategy: 'Strategy',
            handlestrategy0: 'All agree',
            handlestrategy1: 'One user can agree',
            handlestrategy2: 'Proportion',
            handlestrategy3: 'In order of select',
            handlestrategy4: 'Independent',
            percentage: 'Percent',
            backstrategy: 'Back stgy',
            backstrategy1: 'Handle strategy',
            backstrategy2: 'One user back all back',
            backstrategy3: 'Backed by all',
            backstrategy4: 'Independent',
            backstrategy0: 'Cannot back',
            backselect: 'Select receiver',
            backselect_note: 'The handler selects the receiver when backing',
            counterstrategy: 'Countersign',
            counterstrategy0: 'Not',
            counterstrategy1: 'All steps',
            counterstrategy2: 'One step',
            counterstrategy3: 'Proportion',
            backtype: 'Back type',
            backtype0: 'Previous step',
            backtype1: 'First step',
            backtype2: 'Select step',
            sendtoback: 'Send to back step',
            sendtoback_note: 'If the task is backed,it will be directly send to the backed step',
            counterstart: 'Start step',
            counterstart_note: 'Select countersign start step',
            backstep: 'Back step',
            backstep_note: 'Select back steps',
            skipstrategy: 'Skip',
            skip_user: 'Same handler',
            skip_user_note: 'The sender and receiver in the previous step are the same',
            skip_nouser: 'No handler',
            skip_nouser_note: 'No default handler found',
            skip_method: 'Method',
            skip_method_note: 'Skip when the method returns true or "1" or "true"',
        },
        //流程设计步骤按钮
        step_button: {
            button: 'Button',
            remove: 'Del',
            tips: 'Tips',
            tips_addremove: 'Double click to quickly add and remove buttons',
            tips_sort: 'Drag over the selected button to sort',
            display: 'Show title',
            note: 'Note',
            separator: 'Separator',
            add_noselect: 'Please select the button to add!',
            add_selected: ' has been selected!',
            remove_noselect: 'Please select the button to be deleted!',
            notfoundstep: 'No steps found!',
            handlerType0: 'Initiator',
            handlerType1: 'A step handler',
            handlerType2: 'Value field',
            handlerType3: 'Initiator - Dept',
            handlerType4: 'Initiator - Company',
            handlerType5: 'Previous handler - Dept',
            handlerType6: 'Previous handler - Company',
            initiator_parents: 'Initiator - All superiors ',
            prevhandler: 'Previous handler',
            prevhandler_parents: 'Previous handler - All superiors ',
        },
        //流程设计步骤数据
        step_data: {
            data: 'Data',
            table: 'Table',
            status: 'Status',
            edit: 'Edit',
            readonly: 'Readonly',
            hide: 'Hide',
            valid: 'Validate',
            valid_no: 'No',
            valid_yes: 'Yes',
            synctotable: 'Update to data table',
            synctotable_note: 'Whether to update the form data to the bound data table when executing the flow',
            field: 'Field',
            fieldstatus: 'Status',
        },
        //流程设计步骤抄送
        step_copyfor: {
            copyfor: 'Copy for',
            setreceiver: 'Designation',
            setreceover_note: 'Copy to the designation receiver',
            type: 'Type',
            stephandler: 'Step handler',
            stephandler_note: 'Copy to the handler of a step',
            sqlormethod: 'SQL/Method',
            sqlormethod_note: 'The beginning of [sql] indicates the sql statement',
            time: 'Time',
            time_receive: 'Receive',
            time_completed: 'Completed',
            msg: 'To do notice',
            msgtemplete: 'Message Template',
        },
        //流程设计步骤事件
        step_event: {
            event: 'Event',
            submit_before: 'Before submit',
            submit_before_note: 'The method returns characters other than "1" and "true", which represent an error.',
            submit_after: 'After submit',
            submit_after_note: 'Execute after task submited',
            pass_after: 'After pass',
            pass_after_note: 'The procedure shall be implemented after transportation',
            back_before: 'Before back',
            back_before_note: 'The method returns characters other than "1" and "true", which represent an error.',
            back_after: 'After back',
            back_after_note: 'Execute after returning the task',
            back_pass: 'After back and passed',
            back_pass_note: 'Execute after the step is returned and passed',
        },
        //流程设计步骤子流程
        step_subflow: {
            subflow: 'Subflow',
            behavior: 'Strategy',
            behavior0: 'Completed submit',
            behavior1: 'Initiate submit',
            behavior2: 'Initiate auto submit',
            behavior3: 'Complete auto submit',
            instancetype: 'Instance type',
            instancetype0: 'Same instance',
            instancetype1: 'Individual instance',
            activeevent: 'Activate event',
            activeevent_note: 'Execute event before launching sub flow task.',
        },
    },
    //表单设计
    form: {
        name: 'Name',
        delete_noselect: 'You have not selected a form to delete!',
        delete_confirm: 'Are you sure you want to delete the selected form?',
        delete1_noselect: 'You have not selected the form to delete completely!',
        delete1_confirm: 'Are you sure you want to delete the selected form completely?',
        recovery_confirm: 'Are you sure you want to restore this form?',
        export_noselect: 'You have not selected a form to export!',
        load_fail: 'Failed to get the form!',
        attr: 'Attr',
        ctl: 'Control ',
        bind_field: 'Bind field',
        label: 'Label',
        default_value: 'Default',
        default_value_note: 'Select default value',
        width: 'Width',
        width_note: '%/px',
        align: 'Align',
        align_left: 'Left',
        align_center: 'Center',
        align_right: 'Right',
        disabled: 'Disabled',
        readonly: 'Readonly',
        clearable: 'Clearable',
        maxlength: 'Maxlength',
        placeholder: 'Placeholder',
        prefix: 'Prefix icon',
        suffix: 'Suffix icon',
        input_type: 'Input type',
        input_text: 'Text',
        input_password: 'Password',
        style: 'Style',
        event: 'Events',
        script: 'Scripts',
        repeat: ' repeat!',
        search: 'Search',
        source: 'Source',
        source_string_note: 'Format: Option value, Option title;Option value,Option title',
        source_sql_note: 'The query returns two fields, option value and option title',
        source_url_note: 'The post url address returns a json string',
        source_sqlurl: 'Load childs SQL/URL',
        dictchild: 'Dictionary childs',
        multiple: 'Multiple',
        dbconn: 'Db conn',
        dbconn_note: 'Execute SQL data connection',
        dictitem: 'Dictionary',
        valuefield: 'Value field',
        linkage: 'Linkage',
        input: 'Input',
        input_attr: 'Input attr',
        input_delete: 'Delete input',
        textarea: 'Textarea',
        textarea_attr: 'Textarea attr',
        textarea_delete: 'Delete textarea',
        textarea_rows: 'Rows',
        inputnumber: 'Input number',
        inputnumber_attr: 'Input number attr',
        inputnumber_delete: 'Delete input number',
        inputnumber_step: 'Step',
        inputnumber_precision: 'Precision',
        inputnumber_stepstrictly: 'Stepstrictly',
        inputnumber_min: 'Min',
        inputnumber_max: 'Max',
        inputnumber_showbutton: 'Controls',
        inputnumber_controlsposition: 'Controlsposition',
        switch: 'Switch',
        switch_attr: 'Switch attr',
        switch_delete: 'Delete switch',
        switch_text: 'Active text',
        switch_text1: 'Inactive text',
        switch_inlineprompt: 'Inline prompt',
        switch_value: 'Active value',
        switch_value1: 'Inactive value',
        switch_defaultselect: 'Selected',
        switch_color: 'Active color',
        switch_color1: 'Inactive color',
        select: 'Select',
        select_attr: 'Select attr',
        select_delete: 'Delete select',
        cascader: 'Cascader',
        cascader_attr: 'Cascader attr',
        cascader_delete: 'Delete cascader',
        cascader_expandtrigger: 'Expand',
        cascader_expandtrigger_click: 'Click',
        cascader_expandtrigger_hover: 'Hover',
        radio: 'Radio',
        radio_attr: 'Radio attr',
        radio_delete: 'Delete radio',
        rodio_style_normal: 'Normal',
        rodio_style_button: 'Button',
        rodio_style_border: 'Border',
        checkbox: 'Checkbox',
        checkbox_attr: 'Checkbox attr',
        checkbox_delete: 'Delete checkbox',
        hidden: 'Hidden',
        hidden_attr: 'Hidden attr',
        hidden_delete: 'Delete hidden',
        datetime: 'Datetime',
        datetime_attr: 'Datetime attr',
        datetime_delete: 'Delete datetime',
        datetime_selecttype: 'Sel type',
        datetime_date: 'Date',
        datetime_daterange: 'Date range',
        datetime_datetimerange: 'Datetime range',
        datetime_year: 'Year',
        datetime_month: 'Month',
        datetime_monthrange: 'Month rnage',
        datetime_week: 'Week',
        datetime_dates: 'Dates',
        datetime_shortuct: 'Shortuct',
        datetime_separator: 'Separator',
        datetime_separator_note: '-',
        datetime_showformat: 'Show format',
        datetime_showformat_note: 'Example:YYYY-MM-DD',
        datetime_valueformat: 'Value format',
        datetime_time: 'Time',
        datetime_time_start: 'Start',
        datetime_time_end: 'End',
        datetime_time_min: 'Min',
        datetime_time_max: 'Max',
        datetime_time_step: 'Step',
        dict: 'Dictionary',
        dict_attr: 'Dictionary attr',
        dict_delete: 'Delete dictionary',
        dict_selectset: 'Sel set',
        dict_showroot: 'Show root',
        dict_selectroot: 'Sel root',
        dict_selectparent: 'Sel parent',
        dict_multiple: 'Multiple',
        dict_selectsize: 'Quantity',
        dict_selectsize_note: '',
        dict_selectrange: 'Sel range',
        org: 'Organize',
        org_attr: 'Organize attr',
        org_delete: 'Delete organize',
        org_company: 'Company',
        org_dept: 'Dept',
        org_station: 'Station',
        org_user: 'User',
        org_workgroup: 'Workgroup',
        org_selectrange0: 'Initiator dept',
        org_selectrange1: 'Initiator company',
        org_selectrange2: 'Handler dept',
        org_selectrange3: 'Handler company',
        org_setrange: 'Specified',
        file: 'Attachment',
        file_attr: 'Attachment attr',
        file_delete: 'Delete attachment',
        file_type: 'File type',
        file_type_note: 'Example:.jpg,.png,.docx,...',
        file_quantity: 'Quantity',
        file_showtype: 'Show type',
        file_showtype_link: 'Link',
        file_showtype_linkbr: 'Link wrap',
        file_showpicture: 'Picture',
        file_showindex: 'Index',
        file_readonlyshowtype: 'Display mode when readonly',
        file_showstyle: 'Style',
        file_showstyle_note: 'Css style when displayed',
        file_ctltype: 'Ctl type',
        file_ctltype_div: 'Pop-up',
        file_ctlshowtype: 'Show type',
        file_ctlshowtype_list: 'List',
        file_ctlshowtype_avatar: 'Avatar',
        file_ctlshowtype_picturecard: 'Picture',
        file_ctltip: 'Tip',
        file_ctltip_note: '',
        file_buttext: 'Button text',
        appraise: 'Appraise',
        appraise_attr: 'Appraise attr',
        appraise_delete: 'Delete appraise',
        appraise_icon: 'Icon',
        appraise_total: 'Total',
        appraise_allowhalf: 'Allow half',
        html: 'Html editor',
        html_attr: 'Html editor attr',
        html_delete: 'Delete html editor',
        html_height: 'Height',
        html_number: 'Number',
        html_toolbar: 'Toolbar',
        html_toolbar_standard: 'Standard',
        html_toolbar_pithy: 'Pithy',
        label1: 'Label',
        label_attr: 'Label attr',
        label_delete: 'Delete label',
        button: 'Button',
        button_attr: 'Button attr',
        button_delete: 'Delete button',
        button_type: 'Type',
        snumber: 'Serial number',
        snumber_attr: 'Serial number attr',
        snumber_delete: 'Delete serial number',
        snumber_note: 'Select serial number',
        snumber_placeholder: 'Prompt text when empty',
        dt: 'Datatable',
        dt_attr: 'Datatable attr',
        dt_delete: 'Delete datatable',
        dt_size: 'Size',
        dt_size_default: 'Default',
        dt_size_large: 'Large',
        dt_size_small: 'Small',
        dt_stripe: 'Stripe',
        dt_source: 'Source',
        dt_columnset: 'Columns',
        selectdiv: 'Pop up select',
        selectdiv_attr: 'Pop up select attr',
        selectdiv_delete: 'Delete pop up select',
        selectdiv_page: 'Pop up page',
        selectdiv_page_note: 'Select applibrary type',
        delectdiv_valuefield: 'Value field',
        selectdiv_titlefield: 'Title field',
        selectdiv_valueseparator: 'Value separator',
        selectdiv_valueseparator_note: 'Separator between multiple values',
        selectdiv_titleseparator: 'Title separator',
        selectdiv_titleseparator_note: 'Separator between multiple titles',
        selectdiv_wintitle: 'Window title',
        selectdiv_width: 'Ctl W',
        selectdiv_winwidth: 'Window W',
        selectdiv_params: 'Params',
        selectdiv_params_note: 'Js expression',
        sign: 'Signature',
        sign_attr: 'Signature attr',
        sign_delete: 'Delete signature',
        sign_buttontype: 'Button type',
        sign_delfault: 'Default signed',
        saveas: 'Save as',
        saveas_note: 'Form name',
        saveas_notformattr: 'Form attribute not found!',
        saveas_nameempty: 'The form name cannot be empty!',
        saveas_ing: 'Saving…',
        saveas_success: 'Save successfully!',
        saveas_iderror: 'Form ID error!',
        saveas_from: 'Source form not found!',
        saveas_notuser: 'Current user not found!',
        saveas_attrerror: 'Form attribute parsing error!',
        saveform: 'Save',
        save_type: 'Form type cannot be empty!',
        previvew: 'Preview',
        previvew_save: 'Please save the form before previewing!',
        publish: 'Publish',
        publish_ing: 'Publishing…',
        publish_success: 'Publish successfully!',
        publish_fail: 'Publish fail!',
        attribute: 'Attr',
        attribute_manager: 'Manager',
        attribute_dbconn: 'DB conn',
        attribute_table: 'Table',
        attribute_key: 'Key',
        attribute_title: 'Title',
        attribute_expression: 'Title expr',
        attribute_expression_note: '',
        attribute_type: 'Type',
        attribute_labelwidth: 'Label W',
        attribute_labelwidth_note: 'auto',
        attribute_labelsuffix: 'Suffix',
        attribute_labelsuffix_note: '',
        attribute_valid: 'Valid msg',
        attribute_valid_show: 'Show',
        attribute_valid_inline: 'Inline',
        attribute_valid_showicon: 'Icon',
        attribute_border: 'Border',
        subtable: {
            subtable: 'Subtable',
            subtable_attr: 'Subtable attr',
            subtable_delete: 'Delete subtable',
            primarykey: 'Primary key',
            mainfield: 'Main field',
            linkfield: 'Link field',
            tablesize: 'Table size',
            field: 'Field',
            showtitle: 'Show title',
            align: 'Align',
            align_left: 'Left',
            align_center: 'Center',
            align_right: 'Right',
            editcontrol: 'Control',
            sumcolumn: 'Sum',
            showindex: 'Show order',
            remove: 'Remove',
            controltype: 'Control type',
            notfoundedit: 'Not found editor',
            settitle: 'Set Subtable control',
            label: 'Label',
            sum: 'Total',
        },
        //form-design.js中的
        design: {
            string: 'String',
            number: 'Number',
            thousand: 'Thousand',
            percentage: 'Percent',
            title: 'Title',
            value: 'Value',
            code: 'Code',
            note: 'Note',
            other: 'Other',
            dict: 'Dictionary',
            auto_number: 'Auto generate',
            df_org: 'Organize',
            df_org_userid: 'Login user id',
            df_org_username: 'Login user name',
            df_org_useraccount: 'Login user account',
            df_org_deptid: 'Login user dept id',
            df_org_deptname: 'Login user dept name',
            df_org_stationid: 'Login user station id',
            df_org_stationname: 'Login user station name',
            df_org_companyid: 'Login user company id',
            df_org_companyname: 'Login user company name',
            df_org_org: 'Login user organize relation',
            df_org_orgroot: 'Login user organize relation has root',
            df_datetime: 'Datetime',
            df_datetime_shortdate: 'Date(yyyy-MM-dd)',
            df_datetime_longdate: 'Date(yyyy year M month d day)',
            df_datetime_shortdatetime: 'Datetime(yyyy-MM-dd HH:mm)',
            df_datetime_longdatetime: 'Datetime(yyyy year M month d day H hour m minute)',
            df_datetime_shortdatetimes: 'Datetime(yyyy-MM-dd HH:mm:ss)',
            df_datetime_longdatetimes: 'Datetime(yyyy year M month d day H hour m minute s second)',
            df_flow: 'Flow',
            df_flow_id: 'Flow id',
            df_flow_name: 'Flow name',
            df_flow_stepid: 'Step id',
            df_flow_stepname: 'Step name',
            df_flow_taskid: 'Task id',
            df_flow_tasktitle: 'Task title',
            df_flow_instanceid: 'Instance id（Data id）',
            df_flow_groupid: 'Groupid',
            df_flow_receiverid: 'Receiver id',
            df_flow_receivername: 'Receiver name',
        }
    },
    //消息中心
    message: {
        type: {
            site: 'Website',
            phone: 'SMS',
            qywechat: 'Wechat',
            mail: 'Mail',
        },
        receiver: 'Receiver',
        send_type: 'Type',
        contents: 'Contents',
        send: 'Send',
        valid_receiver: 'Please select the recipient',
        valid_sendtype: 'Please select the sending method',
        valid_contents: 'Please enter the message contents',
        msg_content: 'Message contents cannot be empty!',
        msg_receiver: 'Message receiver cannot be empty!',
        msg_success: 'Sending successfully!',
        msg_fail: 'Sending failed!',
        mark: 'Mark all as read',
        flowtask: 'Flow task',
        mark_no: 'No message to mark!',
        mark_success: 'Marking successfully!',
        mark_fail: 'Marking failed!',
        delete_noselect: 'You have not selected a message to delete!',
        delete_confirm: 'Are you sure you want to delete the selected message?',
        unread: 'Unread',
        read_time: 'Read time ',
        read: 'Read',
    },
    //系统管理
    system: {
        //应用程序库
        app: {
            title: 'Title',
            traditional: 'Traditional',
            english: 'English',
            address: 'Address',
            type: 'Type',
            open_mode: 'Open mode',
            width: 'Width',
            height: 'Height',
            select: 'Select',
            button_edit: 'Edit buttons',
            button_type_normal: 'Normal',
            button_type_list: 'List',
            delete_noselect: 'Please select the app to delete!',
            delete_confirm: 'Are you sure you want to delete the selected app?',
            valid_title: 'Please enter the application title',
            valid_address: 'Please enter the application address',
            valid_type: 'Please select an application type',
            valid_openmode: 'Please select the application open mode',
            load_fail: 'Failed to get application data!',
            load_button_fail: 'Failed to get application button!',
            export_noselect: 'You have not selected an application to export!',
            button: 'Button',
        },
        //组织架构
        org: {
            name: 'Name',
            org: 'Organization',
            login_time: 'Login time',
            activity_time: 'Activity time',
            browser: 'Browser',
            login_type: 'Login type',
            offline: 'Offline',
            pc: 'PC',
            wechat: 'Wechat',
            offline_confirm: 'Are you sure you want to force this user offline?',
            operation_success: 'Operation successfully!',
            operation_fail: 'Operation fail!',
            user_editpass_old: 'Old pwd',
            user_editpass_new: 'New pwd',
            user_editpass_newconfirm: 'Confirm',
            user_editpass_valid_newpass: 'Please enter a new pwd to confirm',
            user_editpass_valid_two: 'The two pwd entered are inconsistent',
            user_editpass_valid_old: 'Please enter the old pwd',
            user_editpass_valid_new: 'Please enter a new pwd',
            user_editpass_success: 'Modification successfully！',
            user_editpass_notfound: 'User not found！',
            user_editpass_olderror: 'Old password error!',
            user_editpass_newempty: 'New password is empty!',
            user_editpass_fail: 'Modification failed!',
            user_phone: 'Phone',
            user_mail: 'Mail',
            user_tel: 'Tel',
            user_birthday: 'Birthday',
            user_concat: 'Concat',
            user_loadfail: 'Failed to obtain user information!',
            user_sign: 'Signature',
            user_sign_set: 'Set',
            avatar_upload: 'Upload',
            avatar_save: 'Save',
            avatar_default: 'Restore default',
            avatar_valid_picture: 'The avatar must be in picture format!',
            avatar_valid_size: 'The uploaded image file cannot be larger than 2M!',
            avatar_no: 'No avatar to save!',
            avatar_reset_success: 'Recovery successfully!',
            avatar_reset_fail: 'Recovery failed!',
            shortuct_tips: 'Tips: You cannot set a menu that is not bound to an application as a shortcut menu. Shortcut menu drag selected to sort.',
            shortuct_noselect: 'Please select the menu to add!',
            shortuct_nobind: 'Cannot set a menu without bound application as a shortcut menu!',
            shortuct_selected: 'The current menu has been selected!',
            shortuct_deletenoselect: 'Please select the shortcut menu to be removed!',
            operation_tips: 'Please select the organization to operate on the left',
            search: 'Search',
            search_note: 'Enter keyword search',
            search_result: 'No results were found for "',
            search_result1: '"',
            search_result2: 'Search results for "',
            search_result3: '"',
            workgroup: 'Workgroup',
            type: 'Type',
            type_unit: 'Company',
            type_dept: 'Dept',
            type_station: 'Station',
            status: 'Status',
            status_normal: 'Normal',
            status_frozen: 'Frozen',
            move: 'Move',
            moveto: 'Move to',
            moveto_note: 'Please select the organization to move to',
            moveto_confirmnote: 'Are you sure you want to move?',
            moveto_confirm: 'Confirm Move',
            moveto_success: 'Move successfully!',
            moveto_empty: 'The organization to be moved is empty!',
            moveto_self: 'You cannot move to yourself or your subordinate organization!',
            adduser: 'Add user',
            setmenu: 'Set menu',
            setmenu_show: 'View menu set',
            syncwechat: 'Sync to wechat',
            syncwechat_confirm: 'Are you sure you want to synchronize the entire organizational structure to wechat?',
            syncwechat_completed: 'Synchronization completed!',
            syncwechat_fail: 'Synchronization failed!',
            valid_name: 'Please enter a name',
            valid_type: 'Please select a type',
            valid_status: 'Please select a status',
            org_notfound: 'No organization found!',
            save_confirm: 'Are you sure you want to save?',
            save_confirm1: '',
            save_confirm2: '',
            org_delete_confirm: 'Are you sure you want to delete?',
            org_delete_root: 'Cannot delete organization root!',
            org_sort: 'The current organization has no subordinate organizations, so no sorting is required!',
            org_sortone: 'There is only one subordinate organization in the current organization. No sorting is required!',
            menu_source: 'Source',
            account: 'Account',
            gender: 'Gender',
            gender_man: 'Man',
            gender_woman: 'Woman',
            transfer: 'Transfer to',
            transfer1: 'Transfer',
            transfor_select: 'Please select the organization',
            transfer_confirm: 'Are you sure you want to transfer?',
            transfer_success: 'Transfer successfully!',
            transfer_fail: 'Transfer fail!',
            parttime: 'Part-time',
            transfor_confirm: 'Confirm',
            inorg: 'Organization',
            initpassword: 'Init pwd',
            initpassword_confirm: 'Are you sure you want to initialize the password?',
            initpassword_tips: 'Password initialized to:',
            initpassword_fail: 'Failed to initialize password!',
            valid_user_name: 'Please enter your name',
            valid_user_account: 'Please enter the account number',
            valid_user_gender: 'Please select gender',
            valid_user_status: 'Please select a status',
            valid_user_accountuse: 'The account has been used!',
            delete_confirm: 'Are you sure you want to delete?',
            delete_no: 'The current person has a flow task, which cannot be deleted!',
            user_sort_one: 'There is only one person in the current organization, so you don\'t need to sort!',
            user_load_fail: 'Load error!',
            member: 'Members',
            workgroup_notfound: 'Workgroup not found!',
            iderror: 'id error！',
            workgroup_deleteno: 'Cannot delete root workgroup!',
        },
        //菜单管理
        menu: {
            name: 'Name',
            traditional: 'Traditional',
            english: 'English',
            app: 'App',
            icon: 'Icon',
            color: 'Color',
            update_auth: 'Update authority',
            opeation_tips: 'Please click the left menu to operate',
            valid_title: 'Please enter the menu name',
            notfound: 'Not found!',
            load_fail: 'Load fail!',
            save_confirm: 'Are you sure you want to save?',
            save_confirm1: '',
            save_confirm2: '',
            delete_noselect: 'Please select the menu to delete!',
            delete_confirm: 'Are you sure you want to delete?',
            update_success: 'Update successfully!',
            update_fail: 'Update fail!',
            sort_nochild: 'The current menu has no lower level menu, do not sort!',
            sort_one: 'The current menu has only one submenu, so it is unnecessary to sort!',
            sort_success: 'successfully in saving sorting!',
            sort_fail: 'Failed to save sorting!',
        },
        //数据连接
        dbconn: {
            system: 'System',
            primarytable: 'Primary',
            name: 'Name',
            type: 'Type',
            conn_string: 'String',
            test: 'Test',
            valid_name: 'Please enter the connection name',
            valid_type: 'Please select a connection type',
            valid_string: 'Please enter the connection string',
            delete_select: 'Please select the data connection to delete!',
            delete_confirm: 'Are you sure?',
            test_success: 'Successfully!',
            test_fail: 'Failed to connect to the database. Please check whether the database server is normal and the connection string is correct!',
        },
        //系统按钮库
        button: {
            name: 'Name',
            name_tw: 'Traditional',
            name_en: 'English',
            icon: 'Icon',
            script: 'Scripts',
            script_note: 'Js script',
            type: 'Type',
            address: 'Address',
            address_note: 'Used to verify permissions.',
            valid_name: 'Please enter a button name',
            valid_script: 'Please enter the button script',
            delete_select: 'Please select the button to delete!',
            delete_confirm: 'Are you sure?',
        },
        //数据字典
        dict: {
            title: 'Title',
            title_tw: 'Traditional ',
            title_en: 'English',
            code: 'Code',
            value: 'Value',
            other: 'Other',
            status: 'Status',
            status_normal: 'Normal',
            status_delete: 'Deleted',
            op_note: 'Please click the dictionary item on the left to operate',
            valid_title: 'Please enter the dictionary title',
            valid_code: 'Duplicate unique code!',
            notfound: 'Not found!',
            save_confirm: 'Are you sure you want to save?',
            save_confirm1: '',
            save_confirm2: '',
            delete_select: 'Please select the dictionary to delete!',
            delete_confirm: 'Are you sure you want to delete?',
            delete_notfound: 'Not found!',
            sort_not: 'There is no lower level dictionary in the current dictionary. No sorting is required!',
            sort_onenot: 'There is only one lower level dictionary in the current dictionary. No sorting is required!',
            export_select: 'You have not selected a dictionary to export!',
            import_file: 'The file to be imported must be a json format file!',
        },
        //首页设置
        homeset: {
            name: 'Name',
            title: 'Title',
            title_note: 'Name/Title',
            type_top: 'Top count',
            type_left: 'Left components',
            type_right: 'Right components',
            source: 'Datasource',
            source_vue: 'Vue components',
            source_dbconn: 'Data conn',
            source_source: 'Source',
            source_source_note: 'Sql/Vue components',
            use: 'User',
            use_note: 'Null means everyone',
            link: 'Link url',
            color: 'Color',
            valid_name: 'Please enter a name',
            valid_title: 'Please enter the display title',
            valid_type: 'Please select a type',
            valid_source: 'Please select the data source',
            valid_source1: 'Please enter the source',
            delete_noselect: 'You have not selected the homepage setting to delete!',
            delete_confirm: 'Are you sure?',
            todo: 'To do list',
        },
        //工作日设置
        weekday: {
            year: 'Year',
            days: 'Total working days ',
            days1: '',
            save: 'Save',
            tips: 'Tips: click the cell background to set it as a holiday',
            month: '',
            Monday: 'Mon',
            Tuesday: 'Tue',
            Wednesday: 'Wed',
            Thursday: 'Thu',
            Friday: 'Fir',
            Saturday: 'Sat',
            Sunday: 'Sun',
        },
        //序列号
        number: {
            title: 'Title',
            format: 'Format',
            format_note: '',
            digit: 'Digit',
            current: 'Current',
            type: 'Type',
            type0: 'Do not reset',
            type1: 'Year',
            type2: 'Month',
            type3: 'Day',
            valid_title: 'Please enter a title',
            valid_digit: 'Please enter the number of digits',
            valid_type: 'Please select a type',
            delete_noselect: 'Please select the serial number to delete!',
            delete_confirm: 'Are you sure?',
        },
        //图标
        icon: {
            total: 'Total ',
            total1: ',Enter an icon name to search.',
            copied: 'Copied to clipboard!',
        },
        //日志查询
        log: {
            title: 'Title',
            type: 'Type',
            date: 'Datetime',
            operator: 'Operator',
            details: 'Details',
            view_details: 'View log details',
            ip: 'ip',
            url: 'Source url',
            url1: 'Operation url',
            browse: 'Browser',
            contents: 'Contents',
            before: 'Before operation',
            after: 'After operation',
            other: 'Other contents',
            type_login: 'Login',
            type_system: 'System manage',
            type_flowmanage: 'Flow manage',
            type_flowrun: 'Flow run',
            type_formmanage: 'Form manage',
            type_other: 'Other',
            type_error: 'Exception',
            notfound: 'No logs found!',
        },
        //调查问卷
        quest: {
            subject: 'Subject',
            start_time: 'Start time',
            end_tiem: 'End time',
            no_end_time: 'No end time',
            publisher: 'Publisher',
            submit: 'Submit',
            add_subject: 'Add topic',
            publish: 'Publish time',
            publish1: 'Publish',
            submit_type: 'Submit type',
            anonymous: 'Anonymous',
            submit_confirm: 'Are you sure you want to submit?',
            submit_success: 'Submitted successfully!',
            submit_notfound: 'No questionnaire to submit found!',
            submit_expire: 'The questionnaire has exceeded the end time, unable to submit!',
            submit_fail: 'Submitted failed!',
            create_time: 'Create time',
            participants: 'Participants',
            result_query_user: 'Query users',
            status: 'Status',
            status_editing: 'Editing',
            status_published: 'Published',
            status_result: 'Existing results',
            status_completed: 'Completed',
            status_unknown: 'Unknown',
            topic: 'Topic',
            end_tiem_note: 'Null means no end time',
            valid_subject: 'Topic cannot be empty',
            valid_participants: 'Participant cannot be empty',
            notfound: 'No questionnaire information found!',
            loadfail: 'Failed to obtain questionnaire information!',
            delete_confirm: 'Are you sure?',
            delete_result: 'The questionnaire has already been submitted and cannot be deleted!',
            operation_confirm: 'Are you sure you want to ',
            operation_confirm1: '?',
            success: ' successfully!',
            fail: ' failed!',
            publish_nosubject: 'The questionnaire has no selected topic and cannot be published!',
            cancel_result: 'The questionnaire has already been submitted. Cannot cancel!',
            topic_title: 'Title',
            topic_selecttype: 'Type',
            topic_selecttype0: 'Radio',
            topic_selecttype1: 'Checkbox',
            topic_selecttype2: 'Text',
            topic_selecttype3: 'Textarea',
            topic_style: 'Style',
            topic_style1: 'Css style',
            topic_input: 'Input',
            topic_input_no: 'No',
            topic_valid_title: 'Please enter the topic title',
            topic_loaderror: 'Error loading topic selection!',
            result_subject: 'View by subject',
            result_user: 'View by user',
            result_view: 'View',
            result_view_noauth: 'You do not have permission to view the questionnaire results',
            submit_time: 'Submit time',
            submit_no: 'Not submit',
        },
        //文件管理 
        files: {
            upload: 'Upload',
            newfolder: 'New folder',
            rename: 'Rename',
            move: 'Move',
            share: 'Share',
            delete: 'Delete',
            search_note: 'Enter keyword search',
            search: 'Search',
            name: 'Name',
            modifydate: 'Modify date',
            type: 'Type',
            folder: 'Folder',
            file: 'File',
            size: 'Size',
            selectmoveto: 'Select a folder to move to',
            fileshare: 'File share',
            receiver: 'Receiver',
            expiretime: 'Expire',
            expiretime_note: 'Null means no expiration',
            inputfoldername: 'Please enter a folder name',
            foldernameempty: 'Folder name cannot be empty!',
            folder_empty: 'Folder name is empty!',
            folder_auth: 'Permission error!',
            folder_exists: 'Folder already exists!',
            rename_select: 'Please select a file or folder to rename!',
            rename_inputname: 'Please enter a new name',
            rename_valid: 'Name cannot be empty!',
            rename_fileemtpy: 'File or folder is empty!',
            rename_empty: 'Name is empty!',
            rename_nouser: 'No login!',
            move_select: 'Please select the file or folder to move!',
            move_selectto: 'Please select a folder to move to!',
            move_empty: 'The file or folder to be moved to is empty!',
            delete_select: 'Please select the file or folder to delete!',
            delete_confirm: 'Are you sure you want to delete?',
            share_selectreceiver: 'Please select the recipient',
            share_folder: 'Folder cannot be shared!',
            share_select: 'Please select a file to share!',
            share_success: 'Sharing successfully!',
            share_fail: 'Sharing failed!',
            share_cancel: 'Cancel',
            share_time: 'Share time',
            share_long: 'Permanent',
            share_isview: 'View',
            share_isview_yes: 'Yes',
            share_isview_no: 'No',
            share_cancel_select: 'Please select the file you want to cancel sharing!',
            share_cancel_confirm: 'Are you sure you want to cancel sharing?',
            share_cancel_success: 'Cancel successfully!',
            share_cancel_fail: 'Cancel fail',
            share_user: 'Shared by',
        },
        //内部邮箱
        mail: {
            receiver: 'Receiver',
            copyfor: 'Copy',
            secret: 'Private copy',
            subject: 'Subject',
            color: 'Color',
            attachment: 'Attachments',
            contents: 'Contents',
            savedraft: 'Save',
            send: 'Send',
            send_valid_receiver: 'Please select a receiver',
            send_valid_subject: 'Subject cannot be empty',
            reply: 'Reply',
            redirect: 'Forward',
            edit: 'Edit',
            readlist: 'Reading',
            oldcontents: 'Original message',
            sender: 'Sender',
            date: 'Date',
            send_confirm: 'Are you sure you want to send?',
            valid_receiver: 'Receiver cannot be empty!',
            valid_subject: 'Subject cannot be empty',
            success: ' successfully!',
            fail: ' fail!',
            source: 'Source',
            outbox: 'Outbox',
            inbox: 'Inbox',
            draftbox: 'Draftbox',
            delete1_select: 'Please select the message to delete!',
            delete1_confirm: 'Are you sure?',
            delete_success: 'Delete successfully!',
            delete_fail: 'Delete fail!',
            delete: 'Delete',
            deletedraft_select: 'Please select the message to delete!',
            areyousure: 'Are you sure?',
            thorough: '',
            deletedraft_confirm: '',
            unread: 'Unread',
            delete_select: 'Please select the message to delete!',
            delete_confirm: '',
            delete_confirm1: '',
            view_notfound: 'The message does not exist, please refresh the list!',
            view_noauth: 'You are not authorized to view this message!',
        },
    },
    //文档中心
    doc: {
        doc: 'Documents',
        more: 'More',
        unread: 'Unread',
        title: 'Title',
        date: 'Date',
        dir: 'Dir',
        dir_edit: 'Edit dir',
        dir_add: 'Add sub dir',
        dir_name: 'Dir name',
        dir_name_traditional: 'Traditional',
        dir_name_english: 'English',
        dir_manager: 'Manager',
        dir_manager_note: '',
        dir_publisher_note: '',
        dir_reader: 'Readers',
        dir_reader_note: '',
        dir_notfound: 'Not found!',
        dir_valid_name: 'Please enter a name',
        dir_valid_manager: 'Please select a manager',
        dir_valid_publisher: 'Please select the publisher',
        dir_valid_edit: 'You have no permission to edit dir!',
        dir_valid_add: 'You have no permission to add sub dir!',
        dir_edit_noselect: 'Please select the dir to edit!',
        dir_delete_confirm: 'Are you sure you want to delete this dir?',
        dir_delete_valid: 'No permission to delete dir!',
        dir_delete_hasdoc: 'There are documents under this dir, unable to delete!',
        dir_delete_root: 'Cannot delete the root dir!',
        doc_publisher: 'Publisher',
        doc_publishtime: 'Publish time',
        doc_level: 'Level',
        doc_normal: 'Normal',
        doc_attachment: 'Attachment',
        doc_source: 'Source',
        doc_contents: 'Contents',
        doc_valid_title: 'Please enter the document title',
        doc_valid: 'Document dir, title, content cannot be empty!',
        doc_valid_publish: 'You do not have permission to publish documents for dir!',
        doc_valid_show: 'You are not authorized to view this document!',
        doc_loadfail: 'Load failed!',
        doc_delete_noselect: 'Please select the document to delete!',
        doc_delete_confirm: 'Are you sure you want to delete the document?',
        doc_delete_valid: 'No permission to delete document!',
        doc_notfound: 'Document not found!',
    },
    //应用程序设计
    program: {
        name: 'App name',
        name1: 'Name',
        type: 'App type',
        type1: 'Type',
        delete_noselect: 'You have not selected an app to delete!',
        delete_confirm: 'Are you sure you want to delete the selected app?',
        delete1_noselect: 'You have not selected an app to delete completely!',
        delete1_confirm: 'Are you sure you want to delete the selected app completely?',
        dbconn: 'DB conn',
        editform: 'Edit form',
        selectapptype: 'Select app type',
        win_width: 'Width',
        win_height: 'Height',
        button_Location: 'Location',
        button_Location_row: 'New row',
        button_Location_query: 'After query',
        select_row: 'Select column',
        select_row_no: 'No',
        select_row_radio: 'Radio',
        select_row_checkbox: 'Checkbox',
        pager: 'Pager',
        pager_no: 'No',
        pager_yes: 'Yes',
        table_width: 'Table W',
        table_height: 'Table H',
        table_size: 'Size',
        table_border: 'Border',
        table_column: 'Columns',
        table_column_note: 'el-table-column prop=name label=title',
        scripts: 'Scripts',
        scripts_note: 'Execute after loading data in mounted',
        events: 'Events',
        events_note: 'Add methods to be executed when modify and delete data',
        sort: 'Default sort',
        sort_note: 'Sorting when the list is first opened',
        attr_valid_name: 'Please enter the app name',
        attr_valid_type: 'Please select a type',
        attr_valid_conn: 'Please select a data connection',
        attr_valid_sql: 'Please enter the SQL',
        load_fail: 'Failed to load data!',
        button_auth: 'Authentication',
        button_auth_no: 'No',
        button_auth_yes: 'Yes',
        button_select: 'Select',
        button_showtype: 'Show type',
        button_showtype_normal: 'Normal',
        button_showtype_list: 'List',
        button_showif: 'Show judge',
        button_showif_note: 'Judge whether the button displays script',
        publish: 'Publish',
        publishing: 'Publishing…',
        publish_success: 'Publish successfully!',
        publish_notfound: 'No apps found to publish!',
        publish_fail: 'Publish fail!',
        return: 'Back',
        attr: 'Attr',
        list: 'List',
        button: 'Button',
        validate: 'Validate',
        field: 'Field',
        field_name: 'Field name',
        field_showname: 'Show name',
        field_showtype: 'Show type',
        align: 'Align',
        export_copy: 'Copy List Fields',
        export_copy_confirm: 'Copying will clear the existing export field settings. Are you sure?',
        export_copy_success: 'Copy successfully!',
        export_copy_iderror: 'Parameter error!',
        export_copy_nofield: 'No list field to copy!',
        export_datatype: 'Data type',
        export_format: 'Format',
        export_delete_noselect: 'You have not selected the field to delete!',
        export_delete_confirm: 'Are you sure you want to delete the selected fields?',
        custom: 'Custom',
        custom_note: 'Custom string',
        width: 'Width',
        list_isshow: 'Hide',
        list_issort: 'Sort',
        list_forze: 'Fixed',
        list_forze_no: 'No',
        list_forze_left: 'Left',
        list_forze_right: 'Right',
        list_showstyle: 'Show style',
        list_showstyle_note: 'Css style',
        list_onlybuttoncolumn: 'Only one button column can be added to an app!',
        list_delete_noselect: 'You have not selected a list to delete!',
        list_delete_confirm: 'Are you sure you want to delete the selected list?',
        operator: 'Operator',
        control_name: 'Control name',
        input_type: 'Input type',
        placeholder: 'Placeholder',
        datasource: 'Data source',
        source: 'Source',
        source_note: 'String expression/SQL/URL',
        source_dict: 'Dictionaries',
        valuefield: 'Value field',
        select_range: 'Select range',
        select_type: 'Select type',
        select_size: 'Select quantity',
        query_numberid: 'Digital ID for query',
        string_expression: 'String expression',
        all: 'All',
        query_delete_noselect: 'You have not selected the query to delete!',
        query_delete_confirm: 'Are you sure you want to delete the selected query?',
        valid_all_status: 'Set field status to',
        valid_all_check: 'Set field check to',
        table_name: 'Table name',
        field_note: 'Field note',
        field_status: 'Field status',
        valid_type: 'Check type',
        status_edit: 'Edit',
        status_readonly: 'Readonly',
        status_hidden: 'Hide',
        check_check: 'Check',
        check_notcheck: 'Do\'nt check',
        check_notfoundapp: 'App not found!',
        recovery_confirm: 'Are you sure you want to restore this app?',
        run_delete_noselect: 'You have not selected data to delete!',
        run_delete_confirm: 'Are you sure you want to delete?',
        run_delete_noform: 'No form set for application!',
        rownumber: 'No.',
        export_headtext: 'Excel title',
        export_headtext_note: '',
        export_filename: 'File name',
        export_filename_note: '',
        export_template: 'Template',
        import_table: 'Import table',
        import_table_note: 'Imported data table name',
        import_titlefield: 'Identity column',
        import_titlefield_note: 'Identify fields for single import',
        import_filetypeerr: 'The file to be imported must be in excel format!',
        import_dialogtitle: 'Import',
        import_dialogselectfile: 'Select file',
        import_notfoundmodel: 'Program runtime not found!',
        import_nosettable: 'The table to be imported is not set!',
        import_nosetdbconn: 'No data connection set!',
        import_error: 'Import error! Please contact the administrator!',
    },
}