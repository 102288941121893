﻿<template>
    <el-config-provider :locale="elementLocale">
        <Index :query="query" />
    </el-config-provider>
</template>

<script setup>
    import { ref, inject } from 'vue';
    import { useI18n } from 'vue-i18n';
    import Index from './roadui-pages/index.vue';
    import zhCn from 'element-plus/lib/locale/lang/zh-cn';
    import zhTw from 'element-plus/lib/locale/lang/zh-tw';
    import en from 'element-plus/lib/locale/lang/en';
    const { t, locale } = useI18n();
    const utils = inject('utils');
    const config = inject('config');
    const query = utils.getQuery();
    const elementLocale = ref(locale.value === 'en' ? en : locale.value === 'zh-tw' ? zhTw : zhCn);

    //设置多语言
    utils.t = t;
    utils.locale = locale.value;
</script>
<style>

</style>