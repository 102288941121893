﻿<template>
    <div>
        <el-result icon="error"
                   title=""
                   sub-title="加载页面发生了错误！">
            <template #extra>
                
            </template>
        </el-result>
    </div>
</template>

<script setup>
    
</script>