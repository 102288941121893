﻿export default {
    //系统名称
    SYSTEM_NAME: {
        'zh-cn': 'RProcess',
        'zh-tw': 'RProcess',
        'en': 'RProcess',
    },
    //系统标题
    SYSTEM_TITLE: {
        'zh-cn': '业务平台',
        'zh-tw': '业务平台',
        'en': 'Workflow platform',
    },
    //登录底部信息
    LOGIN_FOOTER: {
        'zh-cn': `<div style="margin-bottom:12px;"><span style="margin-right:10px;">Powered by .net6 element-plus</span>
                   </div>`,
        'zh-tw': `<div style="margin-bottom:12px;"><span style="margin-right:10px;">powered by .net6 element plus</span>
                   </div>`,
        'en': `<div style="margin-bottom:12px;"><span style="margin-right:10px;">powered by .net6 element plus</span>
                   </div>`,
    },
    //CKEDITOR上传图片默认文字
    CKEDITOR_IMAGE_PREVIVETEXT: {
        'zh-cn': '',
        'zh-tw': '',
        'en': '',
    },
    //AES key，如果更换key，注意长度要一致16位。
    AES_KEY: 'RProcess_net_abc',

    //测试环境地址
    //后端地址
    SERVER_DEV_ADDRESS: "http://localhost:21407",
    //后端API地址
    SERVER_DEV_APIADDRESS: 'http://localhost:21407/RProcessApi',
    //后端WEB地址
    SERVER_DEV_WEBADDRESS: "http://localhost:21407/RProcessWeb",
    //根路径
    SERVER_DEV_BASEURL: '',

    //正式环境地址
    //后端地址
    SERVER_PRO_ADDRESS: "",
    //后端API地址
    SERVER_PRO_APIADDRESS: '/RProcessApi',
    //后端WEB地址
    SERVER_PRO_WEBADDRESS: "/RProcessWeb",
    //根路径
    SERVER_PRO_BASEURL: '',
};