﻿export default {
    //公用部分
    common: {
        confirm: '操作確認',
        tips: '提示',
        ok: '確定',
        cancel: '取消',
        parttime: '兼任',
        this_week: '這一周',
        this_month: '當前月',
        last_three_months: '近三月',
        but_primary: '主要',
        but_default: '默認',
        but_success: '成功',
        but_warning: '警告',
        but_danger: '危險',
        but_info: '信息',
        but_text: '文本',
        note: '備註',
        add: '添加',
        delete: '刪除',
        edit: '編輯',
        operation: '操作',
        save: '保存',
        save_child: '保存為下級',
        close: '關閉',
        clear: '清空',
        sort: '排序',
        child_sort: '下級排序',
        export: '導出',
        import: '導入',
        select_file: '選擇文件',
        upload_fail: '文件上傳失敗！',
        import_success: '導入成功！',
        import_notexists: '要導入的文件不存在！',
        import_notjson: '要導入的文件不是有效的json格式文件！',
        import_fail: '導入失敗！',
        print: '打印',
        return: '返回',
    },
    //组件
    components: {
        //组织架构选择组件
        org: {
            org: '組織架構',
            parttime: '兼任',
            workgroup: '工作組',
            search: '搜索',
            search_tip: '輸入關鍵字搜索',
            search_result: '未搜索到與“',
            search_result1: '”相關的結果',
            search_result2: '搜索“',
            search_result3: '”的結果',
            select_size: '當前設置最多只能選擇',
            select_size1: '項！',
        },
        //附件
        file: {
            upload: '上傳',
            delete_confirm: '您確定要刪除嗎？',
            total_zero: '共0個文件',
            valid_type: '不允許上傳的文件類型！',
            upload_fail: '文件上傳失敗！',
            valid_size: '當前設置最多只能添加',
            valid_size1: '個文件！',
            delete_success: '文件刪除成功！',
            delete_fail: '文件刪除失敗！',
            delete_unknown: '未知錯誤！',
            valid_paramempty: '參數為空！',
            valid_notfound: '文件不存在！',
            total: '共',
            total1: '個文件',
        },
    },
    //登录页面
    login: {
        account: '帳號',
        password: '密碼',
        lang: '語言',
        rememberme: '記住我',
        none: '不記住',
        oneday: '一天',
        aweek: '一周',
        login: '登錄',
        validate_account: '請輸入帳號',
        validate_password: '請輸入密碼',
        msg_force: '您的帳號已經在',
        msg_force1: '登錄，您要強行登錄嗎？',
        msg_empty: '帳號或密碼不能為空！',
        msg_error: '帳號或密碼錯誤！',
        msg_frozen: '用戶被凍結！',
        msg_frozen1: '用戶所在組織 ',
        msg_frozen2: ' 被凍結！',
        msg_notfoundorg: '未找到您的所在組織！',
        msg_fail: '登錄失敗！',
    },
    //首页
    index: {
        set_menucolor: '菜單顏色',
        set_menucolor_light: '淺色',
        set_menucolor_drak: '深色',
        set_themecolor: '主题颜色',
        set_navcolor: '導航顏色',
        set_themecolor_theme: '主題色',
        set_themecolor_white: '白色',
        set_hometype: '首頁布局',
        logout: '退出系統',
        download: '下載文件',
        todoitem: '待辦事項',
        message: '消息',
        from: ' - 來自',
        logout_confirm: '您確定要退出系統嗎？',
        loading: '加載中…',
        load_error: '加載頁面發生了錯誤！',
        refresh: '刷新當前頁',
        menu_expand: '展開菜單',
        menu_colse: '關閉菜單',
        ctxmenu_refresh: '刷新',
        ctxmenu_closecurrent: '關閉當前',
        ctxmenu_closeother: '關閉其他',
        ctxmenu_closeall: '關閉所有',
    },
    //流程处理
    flow_handle: {
        //公共
        common: {
            status_normal: '正常',
            status_overdue: '即將超期',
            status_expired: '已超期',
            status_serious: '嚴重超期',
            comment_common: '常用意見',
            comment_comment: '處理意見',
            handle_type: '處理類型',
            flow_status: '流程狀態',
            flow_status_ing: '進行中',
            flow_status_end: '已結束',
            content: '消息內容',
            flow_chat: '流程圖',
            task_type: '任務類型',
            task_type_normal: '常規',
            task_type_assign: '指派',
            task_type_entrust: '委託',
            task_type_redirect: '轉交',
            task_type_back: '退回',
            task_type_copyfor: '抄送',
            task_type_beforeaddwrite: '前加簽',
            task_type_afteraddwrite: '後加簽',
            task_type_addwrite: '加簽',
            task_type_goto: '跳轉',
            task_type_solicitingopinions: '徵求意見',
            task_handletype_wait: '待處理',
            task_handletype_ing: '處理中',
            task_handletype_completed: '已完成',
            task_handletype_otherhandle: '他人已處理',
            task_handletype_backed: '已退回',
            task_handletype_otherbacked: '他人已退回',
            task_handletype_redirect: '已轉交',
            task_handletype_delegated: '已委託',
            task_handletype_read: '已閱知',
            task_handletype_assigned: '已指派',
            task_handletype_jumped: '已跳轉',
            task_handletype_terminated: '已終止',
            task_handletype_otherterminated: '他人已終止',
            task_handletype_suspended: '已暫緩',
            task_handletype_othersuspended: '他人已暫緩',
            task_handletype_signed: '已加簽',
            task_handletype_autoComplete: '自動完成',
            task_handletype_replied: '已回復',
        },
        //待办页面
        todo: {
            title: '標題',
            flow: '流程',
            receive_time: '接收時間',
            completed_time: '完成時間',
            init_time: '發起時間',
            start_time: '開始',
            end_time: '結束',
            query: '查詢',
            step: '步驟',
            sender: '發送人',
            receiver: '接收人',
            handler: '處理人',
            status: '狀態',
            complete_time: '要求完成時間 ',
            no_complete_time: '無要求完成時間',
            handle: '處理',
            batch_handle: '批量處理',
            process: '過程',
            form: '表單',
            urge: '催辦',
            withdraw: '撤回',
            delete: '作廢',
            delete_confirm: '您確定要作廢嗎？',
            delete_success: '作廢成功！',
            delete_error: '執行作廢發生了錯誤！',
            handle_pass: '通過',
            handle_back: '退回',
            handle_noselect: '請選擇要批量處理的任務！',
            handle_comment: '請選輸入處理意見',
            handle_selecttype: '請選擇處理類型',
            handle_success: '成功批量處理了',
            handle_success1: '個任務！',
            handle_error: '批量處理發生了錯誤！',
            withdraw_confirm: '您確定要撤回嗎？',
            withdraw_success: '撤回成功！',
            withdraw_handled: '後續任務已處理，不能撤回！',
            withdraw_error: '執行撤回發生了錯誤！',
            urge_type: '催辦方式',
            urge_msg_receiver: '請選擇接收人',
            urge_msg_type: '請選擇催辦方式',
            urge_msg_content: '請輸入消息內容',
            urge_success: '催辦成功！',
            urge_notfounduser: '未找到當前用戶信息！',
            urge_notfoundtask: '未找到流程任務！',
            process_list: '過程列表',
            process_completed: '已完成',
            process_ing: '處理中',
            process_notarrived: '未到達',
            process_loadflowchartfail: '加載流程圖失敗！',
            entrust_time: '委託時間',
            entrust_withdraw: '收回',
            entrust_withdraw_confirm: '您確定要收回該任務嗎？',
            entrust_withdraw_success: '收回成功，請在待辦事項中查看該任務！',
            entrust_withdraw_handled: '該任務已處理，不能收回！',
            entrust_withdraw_fail: '收回失敗！',
            start_useriderror: '用戶id錯誤！',
            start_flowloadfail: '流程加載失敗！',
            print_commnets: '打印處理意見',
            receive: '接收',
            current: '當前',
            manage: '管理',
            instance_manage: '實例管理',
            edit_status: '修改狀態',
            edit_status_valid: '請選擇狀態',
            edit_status_success: '任務狀態修改成功！',
            edit_status_fail: '修改流程任務狀態發生了錯誤！',
            assign_success: '指派成功！',
            assign_fail: '指派失敗！',
            goto_noselect: '您沒有選擇要跳轉的步驟！',
            goto_success: '跳轉成功！',
            goto_paramserror: '參數錯誤！',
            goto_fail: '跳轉失敗！',
            del_confirm: '您確定要刪除該任務嗎？',
        },
        //流程运行
        run: {
            inputcomments: '請輸入意見',
            sign: '簽章',
            signure: '請簽章！',
            inputsignpwd: '輸入簽章密碼',
            receive: '接收',
            notfoundflowrun: '未找到流程運行時！',
            notfoundsetprun: '未找到流程步驟運行時！',
            handleing: '當前任務正由',
            handleing1: '處理，請等待！',
            notauthview: '您無權查看當前任務！',
            cannotstart: '當前流程',
            cannotstart1: '不能發起新的實例！',
            notfounduser: '未找到用戶登錄信息！',
            notfoundtask: '未找到當前任務！',
            loaderror: '加載錯誤！',
            cannotempty: '不能為空',
            on: '開',
            off: '關',
            saved: '已保存！',
            sendto: '已發送到：',
            completedwait: '已完成，等待他人處理！',
            sendwait: '已發送，等待他人處理！',
            completedwaitstep: '已完成，等待其他步驟處理！',
            sendwaitstep: '已發送，等待其他步驟處理！',
            completed: '已完成！',
            sended: '已發送！',
            backto: '已退回到：',
            backwait: '已退回，等待他人處理！',
            backed: '已退回！',
            postponed: '已暫緩！',
            ended: '已終止！',
            copyfored: '已抄送給：',
            copyfored1: '已抄送！',
            solicitopinion: '已向：',
            solicitopinion1: '徵求意見！',
            solicitopinioned: '已徵求意見！',
            replyed: '已回復！',
            redirect1: '已轉交給：',
            redirected: '已轉交！',
            addwrite1: ' 加簽！',
            addwrited: '已加簽！',
            withdrawed: '已撤回！',
            cannotwithdrawed: '任務已處理，不能撤回！',
            handlesuccess: '處理成功！',
            paramerror: '參數錯誤！',
            flowiderror: '流程id錯誤！',
            knownhandletype: '未知的處理類型！',
            cannothandletask: '您不能處理該任務！',
            taskhandled: '當前任務已處理，不能再次處理！',
            handlerempty: '處理人為空！',
            cannotstarttask: '當前流程不能發起新的任務！',
            grouptaskempty: '當前實例組任務為空！',
            savedataerror: '保存表單數據時發生了錯誤！',
            startsubflorerror: '發起子流程發生了錯誤！',
            subflownocompleted: '子流程對應的任務未完成，不能提交！',
            notfoundcopyfotask: '未找到要抄送的步驟！',
            copyreceiverempty: '抄送接收人為空！',
            notfoundoptionstep: '未找到要徵求意見的步驟！',
            optionreceiverempty: '徵求意見接收人為空！',
            notfoundredirectstep: '未找到要轉交的步驟！',
            redirectreceiverempty: '轉交接收人為空！',
            notfoundaddwritestep: '未找到要加簽的步驟！',
            addwritereceiverempty: '加簽接收人為空！',
            cannotedeleteinstances: '不是發起人不能作廢流程實例！',
            deleteinstanceerror: '作廢流程實例發生錯誤！',
            cannotsetback: '當前步驟設置為不能退回！',
            backnotreceover: '退回沒有找到接收人！',
            handlerflowerror: '處理流程發生了錯誤！',
            confirmsendstep: '確認接收步驟',
            confirmbakcstep: '確認退回步驟',
            process: '處理過程',
            flowchart: '流程圖',
            savesethandler: '請先保存再指定處理人！',
            sethandler: '指定後續步驟處理人',
            taskurge: '任務催辦',
            withdrawconfirm: '您確定要撤回嗎？',
            postponeconfirm: '您確定要暫緩嗎？',
            endconfirm: '您確定要終止嗎？',
            copyfor: '抄送',
            solicitopinions: '徵求意見',
            inputopinions: '請填寫回覆意見！',
            redirect: '轉交',
            addwrite: '加簽',
            selectaddwritereceiver: '選擇加簽接收人',
            stepinsend: '步驟內發送',
            notfoundstep: '未找到步驟！',
            notselectreceiver: '沒有選擇接收人！',
            noselectbackstep: '您沒有選擇要退回的步驟！',
            loadchartfail: '加載流程圖失敗！',
            selectcompletedtime: '選擇完成時間',
            sendnotnextstep: '後續步驟均不符合條件！',
            notselectcompletedtime: '沒有選擇完成時間！',
            notselectsendstep: '您沒有選擇接收步驟！',
            selecthandler: '選擇處理人',
            sethandlersuccess: '設置成功！',
            sethandlerfail: '設置失敗！',
            sign_password: '密碼',
            sing_password_valid: '請輸入密碼',
            sing_passwordempty: '密碼不能為空！',
            sing_passworderror: '密碼錯誤！',
            visibility: '可見性',
            visibility0: '僅自己可見',
            visibility1: '流程參與人可見',
            visibility_valid: '請選擇可見性',
            note: '說明',
            solicitopinion_note: '接收人可看見您在這裡輸入的說明',
            view_reply: '查看回復',
            reply_time: '回復時間',
            reply_not: '未回復',
            solicitopinion_reply: '回復',
            form_valid_error: '表單驗證錯誤，請檢查表單必填項等信息！',
            completedconfirm: '您確定要完成嗎？',
        },
    },
    //流程设计
    flow_design: {
        status: {
            unknown: '未知狀態',
            indesign: '設計中',
            published: '已發布',
            uninstall: '已卸載',
            deleted: '已刪除',
        },
        //委托
        entrust: {
            user: '委託人',
            receiver: '接收人',
            edit_time: '編輯時間',
            all_flow: '所有流程',
            all_note: '為空表示所有流程',
            valid_receiver: '請選擇接收人',
            valid_starttime: '請選擇開始時間',
            valid_endtime: '請選擇結束時間',
            status_nostart: '未開始',
            status_ing: '委託中',
            status_end: '已結束',
            status_unknown: '未知',
            load_fail: '獲取數據失敗！',
            save_success: '保存成功！',
            save_fail: '保存失敗！',
            datavalid_error: '數據驗證錯誤！',
            delete_noselect: '您沒有選擇要刪除的委託！',
            delete_confirm: '您確定要刪除所選委託嗎？',
            delete_success: '刪除成功！',
            delete_fail: '刪除失敗！',
        },
        //意见
        comments: {
            use: '使用人',
            comments: '意見',
            all_user: '所有人員',
            source: '來源',
            user_add: '用戶添加',
            admin_add: '管理員添加',
            sort: '排序',
            edit_note: '編輯時不能修改使用人員',
            validate_comment: '請輸入意見',
            delete_noselect: '您沒有選擇要刪除的意見！',
        },
        //接口系统
        apisystem: {
            name: '系統名稱',
            identification: '系統標識',
            key: '調用KEY',
            update: '更新',
            valid_name: '請輸入系統名稱',
            valid_ident: '請輸入系統標識',
            valid_key: '請輸入調用KEY',
            delete_noselect: '您沒有選擇要刪除的接口系統！',
            delete_confirm: '您確定要刪除所選接口系統嗎？',
        },
        //流程设计
        design: {
            hasdesign: '當前有流程正在設計中，請先關閉設計中的流程再打開該流程！',
            new: '新建流程',
            new1: '新建',
            design: '設計流程',
            delete_noselect: '您沒有選擇要刪除的流程！',
            delete_confirm: '您確定要刪除所選流程嗎？',
            delete1: '徹底刪除',
            delete1_noselect: '您沒有選擇要徹底刪除的流程！',
            delete1_confirm: '您確定要徹底刪除所選流程嗎？',
            delete1_success: '徹底刪除成功！',
            delete1_hastask: '已有實例任務，不能徹底刪除！',
            delete1_fail: '徹底刪除失敗！',
            recovery: '恢復',
            recovery_confirm: '您確定要恢復該流程嗎？',
            recovery_success: '恢復成功！',
            recovery_fail: '恢復失敗！',
            export_noselect: '您沒有選擇要導出的流程！',
            name: '流程名稱',
            step: '流程步驟',
            type: '流程分類',
            system: '所屬系統',
            manager: '流程管理',
            instancemanager: '實例管理',
            debug: '調試模式',
            debug_user: '調試人員',
            icon: '流程圖標',
            color: '顏色',
            color_note: '流程發起頁面顯示的圖標和顏色',
            dbconn: '數據連接',
            dbtable: '數據表',
            tableprimary: '表主鍵',
            idevent: '標識事件',
            idfield: '標識字段',
            idvalue: '標識值',
            event_completed: '完成後事件',
            event_completed_note: '流程審批結束後執行',
            event_postpone: '暫緩後事件',
            event_postpone_note: '流程審批暫緩後執行',
            event_end: '終止後事件',
            event_end_note: '流程審批終止後執行',
            event_delete: '作廢后事件',
            event_delete_note: '流程作廢后執行',
            subflowstep: '子流程步驟',
            create_time: '創建時間',
            create_user: '創建人',
            attr: '屬性',
            subflow: '子流程',
            line: '直線',
            brokenline: '折線',
            copy: '複製',
            copy_noselect: '請選擇要複製的步驟！',
            link_noselect: '請選擇要連接的步驟！',
            saveas: '另存為',
            saveas_success: '另存成功！',
            saveas_fail: '另存失敗！',
            publish: '發布',
            uninstall: '卸載',
            valid_name: '請輸入新流程名稱',
            valid_name1: '流程名稱不能為空，請在屬性中設置！',
            valid_type: '流程分類不能為空，請在屬性中設置！',
            valid_step: '流程至少需要一個步驟！',
            notfound: '未找到流程！',
            notauth: '您沒有權限管理該流程！',
            openfail: '打開失敗！',
            noflowtips: '請先新建或打開一個流程！',
            iduseriderror: '流程id或流程名稱或用戶id錯誤！',
            ing: '正在',
            success: '成功！',
            fail: '失敗！',
            jsonerror: 'Json解析錯誤！',
            flowiderror: '流程id錯誤！',
            circulation_conditions: '流轉條件',
            deleteobj_noselect: '請選擇要刪除的對象！',
            deleteobj_confirm: '您確認要刪除選定對象嗎？',
            line_data: '數據條件',
            line_dataexpression: '數據條件表達式',
            line_and: '並且',
            line_or: '或者',
            line_text: '條件標籤',
            line_pdtype: '判斷方式',
            line_text_note: '連線上顯示的條件標籤',
            line_org: '組織架構',
            line_orgexpression: '組織架構表達式',
            line_orgselect: '選擇組織架構',
            line_sender: '發送者',
            line_initiator: '發起者',
            line_in: '屬於',
            line_notin: '不屬於',
            line_method: '自定義方法',
            line_method1: '方法',
            line_note1: '1、方法格式為：命名空間.類名.方法名。例：RProcess.Web.Controllers.TestController.TestLineCondition',
            line_note2: '2、方法返回布爾類型（bool）true或字符串類型（string）"true"、"1"時條件成立，否則條件不成立。',
            line_sql: 'SQL查詢',
            line_sql_note1: '1、SQL查詢有返回結果表示條件成立，否則條件不成立，SQL中可以寫通配符。',
            line_sql_note2: '2、為了提高查詢性能，SQL應當只返回一行一列數據。',
            line_conditions_dy: '等於',
            line_conditions_bdy: '不等於',
            line_conditions_day: '大於',
            line_conditions_dydy: '大於等於',
            line_conditions_xy: '小於',
            line_conditions_xydy: '小於等於',
            line_conditions_like: '包含',
            line_conditions_notlike: '不包含',
            line_conditions_subtablesumdy: '子表求和等於',
            line_conditions_subtablesumbdy: '子表求和不等於',
            line_conditions_subtablesumday: '子表求和大於',
            line_conditions_subtablesumdydy: '子表求和大於等於',
            line_conditions_subtablesumxy: '子表求和小於',
            line_conditions_subtablesumxydy: '子表求和小於等於',
        },
        //流程设计步骤基本
        step_base: {
            base: '基本',
            stepname: '步驟名稱',
            showcomment: '意見顯示',
            commentfile: '可傳附件',
            commentfile_note: '填寫處理意見時是否可以上傳附件',
            autoconfirm: '有處理人直接發送',
            autoconfirm_note: '下一步有默認處理人時直接發送，不彈出發送確認窗口。',
            worktime: '工時(天)',
            sendsettiem: '發送時指定完成時間',
            signtype: '審簽類型',
            signtype1: '無簽批意見欄',
            signtype2: '有簽批意見 - 無簽章',
            signtype3: '有簽批意見 - 須簽章',
            signtype4: '有簽批意見 - 須密碼簽章',
            expire: '超期提醒',
            expireday: '提前',
            expireday1: '天',
            expire_interval: '間隔',
            archive: '是否歸檔',
            batch: '批量處理',
            batch_note: '設置開啟該步驟任務才在批量處理列表中顯示',
            concurrent: '並發控制',
            concurrent_note: '步驟有多人處理時，是否同時只能有一人處理。',
            expirehandle: '超時處理',
            expirehandle1: '不處理',
            expirehandle2: '自動提交',
            sendmsg: '發送提示語',
            backmsg: '退回提示語',
            form: '表單',
            mobileform: '移動端表單',
        },
        //流程设计步骤策略
        step_behavior: {
            behavior: '策略',
            flowtype: '流轉類型',
            flowtype1: '單選一個步驟',
            flowtype2: '多選幾個步驟 - 默認選中第一個',
            flowtype3: '多選幾個步驟 - 默認全部選中',
            flowtype4: '多選幾個步驟 - 默認全部選中不能取消',
            flowtype5: '根據條件判斷 - 無後續步驟時提示',
            flowtype6: '根據條件判斷 - 無後續步驟時完成',
            selecttype: '選擇類型',
            selecttype_org: '組織選擇類型',
            selecttype_org_unit: '單位',
            selecttype_org_dept: '部門',
            selecttype_org_station: '崗位',
            selecttype_org_user: '人員',
            selecttype_org_wg: '工作組',
            runselect: '允許選擇',
            runselect_note: '是否允許發送人在處理時自己選擇該步驟的接收人',
            selectquantity: '數量',
            selectquantity_note: '發送時允許選擇的組織人員數量，0或空表示不限制。',
            handlertype: '處理者類型',
            selectrange: '選擇範圍',
            selectrange1: '默認處理者選中',
            selectrange2: '默認處理者不選中',
            selectrangeset: '指定選擇範圍',
            handlersetp: '處理者步驟',
            handlersetp_note: '處理者類型為某一步時的步驟',
            addwriterange: '加簽範圍',
            addwriterange_note: '指定加簽選擇範圍',
            defaulthandler: '默認處理人',
            defaulthandler_note: '將該步驟的默認處理人作為加簽選擇範圍',
            valuefield: '值字段',
            lasthandler: '上次處理者',
            lasthandler_note: '如果沒有找到默認處理者，則讀取步驟上次的處理人作為默認處理者。',
            defaultsql: '默認處理者',
            defaultsql1: 'SQL或方法',
            defaultsql_note: '[sql]開頭的字符串表示為sql語句，否則表示為方法。',
            defaulthandler1: '默認處理者',
            defaultset: '指定默認處理者',
            handlestrategy: '處理策略',
            handlestrategy0: '所有人同意',
            handlestrategy1: '一人同意即可',
            handlestrategy2: '依據人數比例',
            handlestrategy3: '按選擇人員順序處理',
            handlestrategy4: '獨立處理',
            percentage: '百分比',
            backstrategy: '退回策略',
            backstrategy1: '根據處理策略退回',
            backstrategy2: '一人退回全部退回',
            backstrategy3: '所有人退回才退回',
            backstrategy4: '獨立退回',
            backstrategy0: '不能退回',
            backselect: '選擇退回接收人',
            backselect_note: '退回時處理人自己選擇接收人',
            counterstrategy: '會簽策略',
            counterstrategy0: '不會簽',
            counterstrategy1: '所有步驟同意',
            counterstrategy2: '一個步驟同意即可',
            counterstrategy3: '依據比例',
            backtype: '退回類型',
            backtype0: '退回前一步',
            backtype1: '退回第一步',
            backtype2: '退回某一步',
            sendtoback: '發送到退回步驟',
            sendtoback_note: '如果是退回任務時直接發送到退回的步驟',
            counterstart: '會簽起點',
            counterstart_note: '選擇會簽起點步驟',
            backstep: '退回步驟',
            backstep_note: '選擇退回類型為"退回某一步"時的步驟',
            skipstrategy: '跳過策略',
            skip_user: '同一處理人',
            skip_user_note: '上一步發送人和該步接收人為同一人時跳過',
            skip_nouser: '無默認處理人',
            skip_nouser_note: '沒有找到默認處理人時跳過',
            skip_method: '方法',
            skip_method_note: '方法返回布爾true或字符串"1"或"true"時跳過',
        },
        //流程设计步骤按钮
        step_button: {
            button: '按鈕',
            remove: '移出',
            tips: '操作提示',
            tips_addremove: '在按鈕上雙擊可快速添加和移出按鈕。',
            tips_sort: '在已選擇按鈕上拖動可排序。',
            display: '按鈕顯示',
            note: '按鈕說明',
            separator: '分隔線',
            add_noselect: '請選擇要添加的按鈕！',
            add_selected: '按鈕已經選擇了！',
            remove_noselect: '請選擇要移出的按鈕！',
            notfoundstep: '沒有找到步驟！',
            handlerType0: '發起者',
            handlerType1: '某一步驟處理者',
            handlerType2: '字段值',
            handlerType3: '發起者 - 部門',
            handlerType4: '發起者 - 單位',
            handlerType5: '前一步處理者 - 部門',
            handlerType6: '前一步處理者 - 單位',
            initiator_parents: '發起者 - 所有上級',
            prevhandler: '前一步處理者',
            prevhandler_parents: '前一步處理者 - 所有上級',
        },
        //流程设计步骤数据
        step_data: {
            data: '數據',
            table: '數據表',
            status: '狀態',
            edit: '編輯',
            readonly: '只讀',
            hide: '隱藏',
            valid: '數據驗證',
            valid_no: '不驗證',
            valid_yes: '驗證',
            synctotable: '更新到數據表',
            synctotable_note: '此步驟執行流程時是否將表單數據同步更新到綁定的數據表',
            field: '字段',
            fieldstatus: '字段狀態',
        },
        //流程设计步骤抄送
        step_copyfor: {
            copyfor: '抄送',
            setreceiver: '指定接收人',
            setreceover_note: '抄送给指定的接收人',
            type: '類型',
            stephandler: '步驟處理人員',
            stephandler_note: '抄送給某一步驟的處理人員',
            sqlormethod: 'SQL或方法',
            sqlormethod_note: '通過sql或方法返回接收人，[sql]開頭表示sql語句，否則表示方法。',
            time: '抄送時間',
            time_receive: '步驟接收時',
            time_completed: '步驟完成時',
            msg: '待辦通知',
            msgtemplete: '消息模板',
        },
        //流程设计步骤事件
        step_event: {
            event: '事件',
            submit_before: '提交前事件',
            submit_before_note: '方法返回字符串1、true以外的其他字符，表示為錯誤信息，返回客戶端提示，任務不提交。',
            submit_after: '提交後事件',
            submit_after_note: '任務提交後執行',
            pass_after: '步驟提交通過後事件',
            pass_after_note: '步驟提交通過後執行',
            back_before: '退回前事件',
            back_before_note: '方法返回字符串1、true以外的其他字符，表示為錯誤信息，返回客戶端提示，任務不退回。',
            back_after: '退回後事件',
            back_after_note: '任務退回後執行',
            back_pass: '步驟退回通過後事件',
            back_pass_note: '步驟退回通過後執行',
        },
        //流程设计步骤子流程
        step_subflow: {
            subflow: '子流程',
            behavior: '子流程策略',
            behavior0: '子流程完成才能提交',
            behavior1: '子流程發起即可提交',
            behavior2: '子流程發起後自動提交',
            behavior3: '子流程完成後自動提交',
            instancetype: '實例類型',
            instancetype0: '所有人同一實例',
            instancetype1: '每個人單獨實例',
            activeevent: '激活前事件',
            activeevent_note: '發起子流程任務前設置子流程業務數據，任務標題等操作。',
        },
    },
    //表单设计
    form: {
        name: '表單名稱',
        delete_noselect: '您沒有選擇要刪除的表單！',
        delete_confirm: '您確定要刪除所選表單嗎？',
        delete1_noselect: '您沒有選擇要徹底刪除的表單！',
        delete1_confirm: '您確定要徹底刪除所選表單嗎？',
        recovery_confirm: '您確定要恢復該表單嗎？',
        export_noselect: '您沒有選擇要導出的表單！',
        load_fail: '獲取表單信息失敗！',
        attr: '屬性',
        ctl: '控件',
        bind_field: '綁定字段',
        label: '標籤',
        default_value: '默認值',
        default_value_note: '選擇默認值',
        width: '寬度',
        width_note: '%或px',
        align: '對齊方式',
        align_left: '左對齊',
        align_center: '居中',
        align_right: '右對齊',
        disabled: '禁用',
        readonly: '只讀',
        clearable: '可清除',
        maxlength: '最大字符',
        placeholder: '背景文字',
        prefix: '前綴圖標',
        suffix: '後綴圖標',
        input_type: '輸入類型',
        input_text: '明文',
        input_password: '密碼',
        style: '樣式',
        event: '事件',
        script: '腳本',
        repeat: ' 重複！',
        search: '搜索',
        source: '選項來源',
        source_string_note: '格式：選項1值,選項1標題;選項2值,選項2標題...（字符串不能有單引號)',
        source_sql_note: '查詢返回兩個字段，選項值和選項標題。如果只返回一個字段，則選項值和選項標題一樣。',
        source_url_note: 'post一個url地址，地址返回json字符串。',
        source_sqlurl: '加載下級SQL/URL',
        dictchild: '數據字典下級',
        multiple: '多選',
        dbconn: '數據連接',
        dbconn_note: '選擇執行SQL的數據連接',
        dictitem: '字典項',
        valuefield: '值字段',
        linkage: '聯動',
        input: '輸入框',
        input_attr: '輸入框屬性',
        input_delete: '刪除輸入框',
        textarea: '文本域',
        textarea_attr: '文本域屬性',
        textarea_delete: '刪除文本域',
        textarea_rows: '行數',
        inputnumber: '數字輸入框',
        inputnumber_attr: '數字輸入框屬性',
        inputnumber_delete: '刪除數字輸入框',
        inputnumber_step: '步長',
        inputnumber_precision: '小數位',
        inputnumber_stepstrictly: '只能輸入步長的倍數',
        inputnumber_min: '最小值',
        inputnumber_max: '最大值',
        inputnumber_showbutton: '顯示控制按鈕',
        inputnumber_controlsposition: '按鈕在右邊',
        switch: '開關',
        switch_attr: '開關屬性',
        switch_delete: '刪除開關',
        switch_text: '選中文本',
        switch_text1: '非選中文本',
        switch_inlineprompt: '是否顯示在點內',
        switch_value: '選中時值',
        switch_value1: '非選中時值',
        switch_defaultselect: '默認是否選中',
        switch_color: '選中顏色',
        switch_color1: '非選中顏色',
        select: '下拉選擇',
        select_attr: '下拉選擇屬性',
        select_delete: '刪除下拉選擇',
        cascader: '級聯選擇',
        cascader_attr: '級聯選擇屬性',
        cascader_delete: '刪除級聯選擇',
        cascader_expandtrigger: '展開方式',
        cascader_expandtrigger_click: '單擊',
        cascader_expandtrigger_hover: '移入',
        radio: '單選按鈕',
        radio_attr: '單選按鈕屬性',
        radio_delete: '刪除單選按鈕',
        rodio_style_normal: '常規',
        rodio_style_button: '按鈕',
        rodio_style_border: '邊框',
        checkbox: '複選按鈕',
        checkbox_attr: '複選按鈕屬性',
        checkbox_delete: '刪除複選按鈕',
        hidden: '隱藏域',
        hidden_attr: '隱藏域屬性',
        hidden_delete: '刪除隱藏域',
        datetime: '日期時間',
        datetime_attr: '日期時間屬性',
        datetime_delete: '刪除日期時間',
        datetime_selecttype: '選擇類型',
        datetime_date: '日期',
        datetime_daterange: '日期範圍',
        datetime_datetimerange: '日期時間範圍',
        datetime_year: '年',
        datetime_month: '月',
        datetime_monthrange: '月範圍',
        datetime_week: '周',
        datetime_dates: '多個日期',
        datetime_shortuct: '快捷選項',
        datetime_separator: '分隔符',
        datetime_separator_note: '-',
        datetime_showformat: '顯示格式',
        datetime_showformat_note: '例：YYYY-MM-DD',
        datetime_valueformat: '值格式',
        datetime_time: '時間 - time',
        datetime_time_start: '開始時間',
        datetime_time_end: '結束',
        datetime_time_min: '最早',
        datetime_time_max: '最晚',
        datetime_time_step: '步長',
        dict: '數據字典',
        dict_attr: '數據字典屬性',
        dict_delete: '刪除數據字典',
        dict_selectset: '選擇設置',
        dict_showroot: '顯示根',
        dict_selectroot: '選擇根',
        dict_selectparent: '選擇父節點',
        dict_multiple: '多選',
        dict_selectsize: '選擇個數',
        dict_selectsize_note: '0或空不限制',
        dict_selectrange: '選擇範圍',
        org: '組織架構',
        org_attr: '組織架構屬性',
        org_delete: '刪除組織架構',
        org_company: '單位',
        org_dept: '部門',
        org_station: '崗位',
        org_user: '用戶',
        org_workgroup: '工作組',
        org_selectrange0: '發起者部門',
        org_selectrange1: '發起者單位',
        org_selectrange2: '處理者部門',
        org_selectrange3: '處理者單位',
        org_setrange: '指定範圍',
        file: '附件',
        file_attr: '附件屬性',
        file_delete: '刪除附件',
        file_type: '文件類型',
        file_type_note: '可上傳的文件類型，例：.jpg,.png,.docx等。',
        file_quantity: '文件數量',
        file_showtype: '顯示類型',
        file_showtype_link: '鏈接',
        file_showtype_linkbr: '鏈接換行',
        file_showpicture: '顯示圖片',
        file_showindex: '顯示序號',
        file_readonlyshowtype: '只讀時的顯示方式',
        file_showstyle: '顯示樣式',
        file_showstyle_note: '顯示時的css樣式',
        file_ctltype: '控件類型',
        file_ctltype_div: '彈出層',
        file_ctlshowtype: '顯示方式',
        file_ctlshowtype_list: '列表',
        file_ctlshowtype_avatar: '頭像',
        file_ctlshowtype_picturecard: '照片牆',
        file_ctltip: '提示',
        file_ctltip_note: '對上傳文件的要求說明等提示',
        file_buttext: '按鈕文字',
        appraise: '評分',
        appraise_attr: '評分屬性',
        appraise_delete: '刪除評分',
        appraise_icon: '圖標',
        appraise_total: '總數',
        appraise_allowhalf: '允許半選',
        html: 'Html編輯器',
        html_attr: 'Html編輯器屬性',
        html_delete: '刪除Html編輯器',
        html_height: '高度',
        html_number: '數字',
        html_toolbar: '工具欄',
        html_toolbar_standard: '標準',
        html_toolbar_pithy: '簡潔',
        label1: 'Label標籤',
        label_attr: 'Label標籤屬性',
        label_delete: '刪除Label標籤',
        button: '按鈕',
        button_attr: '按鈕屬性',
        button_delete: '刪除按鈕',
        button_type: '類型',
        snumber: '流水號',
        snumber_attr: '流水號屬性',
        snumber_delete: '刪除流水號',
        snumber_note: '選擇流水號',
        snumber_placeholder: '沒有流水號時顯示的提示文字',
        dt: '數據表格',
        dt_attr: '數據表格屬性',
        dt_delete: '數據表格刪除',
        dt_size: '尺寸',
        dt_size_default: '默認',
        dt_size_large: '大',
        dt_size_small: '小',
        dt_stripe: '斑馬紋',
        dt_source: '數據來源',
        dt_columnset: '列設置',
        selectdiv: '彈出選擇',
        selectdiv_attr: '彈出選擇屬性',
        selectdiv_delete: '刪除彈出選擇',
        selectdiv_page: '彈出頁面',
        selectdiv_page_note: '請選擇應用分類',
        delectdiv_valuefield: '值字段',
        selectdiv_titlefield: '標題字段',
        selectdiv_valueseparator: '值分隔符',
        selectdiv_valueseparator_note: '多選時多個值之間的分隔符',
        selectdiv_titleseparator: '標題分隔符',
        selectdiv_titleseparator_note: '多選時多個標題之間的分隔符',
        selectdiv_wintitle: '窗口標題',
        selectdiv_width: '控件寬度',
        selectdiv_winwidth: '窗口寬度',
        selectdiv_params: '參數',
        selectdiv_params_note: 'js表達式，例：\'size=5&test=\'+this.formData[\'id\']',
        sign: '簽章',
        sign_attr: '簽章屬性',
        sign_delete: '刪除簽章',
        sign_buttontype: '按鈕類型',
        sign_delfault: '默認已簽',
        saveas: '表單另存為',
        saveas_note: '要另存為的表單名稱',
        saveas_notformattr: '未找到表單屬性！',
        saveas_nameempty: '表單名稱不能為空！',
        saveas_ing: '正在保存…',
        saveas_success: '保存成功！',
        saveas_iderror: '表單id錯誤！',
        saveas_from: '未找到源表單！',
        saveas_notuser: '未找到當前用戶！',
        saveas_attrerror: '表單屬性解析錯誤！',
        saveform: '保存表單',
        save_type: '表單分類不能為空！',
        previvew: '預覽表單',
        previvew_save: '請先保存表單，再預覽！',
        publish: '發布表單',
        publish_ing: '正在發布…',
        publish_success: '發布成功！',
        publish_fail: '發布失敗！',
        attribute: '表單屬性',
        attribute_manager: '管理人員',
        attribute_dbconn: '數據連接',
        attribute_table: '數據表',
        attribute_key: '主鍵',
        attribute_title: '標題字段',
        attribute_expression: '標題表達式',
        attribute_expression_note: '',
        attribute_type: '表單分類',
        attribute_labelwidth: '標籤寬度',
        attribute_labelwidth_note: '默認auto',
        attribute_labelsuffix: '標籤後綴',
        attribute_labelsuffix_note: '默認無',
        attribute_valid: '檢驗信息',
        attribute_valid_show: '是否顯示',
        attribute_valid_inline: '是否行內顯示',
        attribute_valid_showicon: '是否顯示圖標',
        attribute_border: '表格是否有邊框',
        subtable: {
            subtable: '子表',
            subtable_attr: '子表屬性',
            subtable_delete: '刪除子表',
            primarykey: '子表主鍵',
            mainfield: '主表字段',
            linkfield: '關聯字段',
            tablesize: '表格大小',
            field: '字段',
            showtitle: '顯示標題',
            align: '對齊',
            align_left: '左對齊',
            align_center: '居中',
            align_right: '右對齊',
            editcontrol: '編輯控件',
            sumcolumn: '合計',
            showindex: '顯示順序',
            remove: '移出',
            controltype: '控件類型',
            notfoundedit: '未找到editor對象！',
            settitle: '設置子表控件',
            label: '標籤',
            sum: '合計',
        },
        //form-design.js中的
        design: {
            string: '字符',
            number: '數字',
            thousand: '千分符',
            percentage: '百分比',
            title: '標題',
            value: '值',
            code: '唯一代碼',
            note: '備註',
            other: '其他',
            dict: '數據字典',
            auto_number: '自動生成流水號',
            df_org: '組織架構相關選項',
            df_org_userid: '當前用戶id',
            df_org_username: '當前用戶姓名',
            df_org_useraccount: '當前用戶帳號',
            df_org_deptid: '當前用戶所在部門id',
            df_org_deptname: '當前用戶所在部門名稱',
            df_org_stationid: '當前用戶所在崗位id',
            df_org_stationname: '當前用戶所在崗位名稱',
            df_org_companyid: '當前用戶所在單位id',
            df_org_companyname: '當前用戶所在單位名稱',
            df_org_org: '當前用戶組織架構關係',
            df_org_orgroot: '當前用戶組織架構關係包含根',
            df_datetime: '日期時間相關選項',
            df_datetime_shortdate: '日期(yyyy-MM-dd)',
            df_datetime_longdate: '日期(yyyy年M月d日)',
            df_datetime_shortdatetime: '日期時間(yyyy-MM-dd HH:mm)',
            df_datetime_longdatetime: '日期時間(yyyy年M月d日 H時m分)',
            df_datetime_shortdatetimes: '日期時間(yyyy-MM-dd HH:mm:ss)',
            df_datetime_longdatetimes: '日期時間(yyyy年M月d日 H時m分s秒)',
            df_flow: '流程相關選項',
            df_flow_id: '當前流程id',
            df_flow_name: '當前流程名稱',
            df_flow_stepid: '當前步驟id',
            df_flow_stepname: '當前步驟名稱',
            df_flow_taskid: '當前任務id',
            df_flow_tasktitle: '當前任務標題',
            df_flow_instanceid: '當前實例id（業務數據id）',
            df_flow_groupid: '當前審批分組id（GroupId）',
            df_flow_receiverid: '當前任務接收者id',
            df_flow_receivername: '當前任務接收者姓名',
        },
    },
    //消息中心
    message: {
        type: {
            site: '站內消息',
            phone: '手機短信',
            qywechat: '企業微信',
            mail: '郵件',
        },
        receiver: '接收人員',
        send_type: '發送方式',
        contents: '消息內容',
        send: '發送',
        valid_receiver: '請選擇接收人',
        valid_sendtype: '請選擇發送方式',
        valid_contents: '請輸入消息內容',
        msg_content: '消息內容不能為空！',
        msg_receiver: '消息接收人員不能為空！',
        msg_success: '發送成功！',
        msg_fail: '發送失敗！',
        mark: '全部標記為已讀',
        flowtask: '流程任務',
        mark_no: '沒有需要標記的消息！',
        mark_success: '標記成功！',
        mark_fail: '標記失敗！',
        delete_noselect: '您沒有選擇要刪除的消息！',
        delete_confirm: '您確定要刪除所選消息嗎？',
        unread: '未讀',
        read_time: '閱讀時間 ',
        read: '已讀',
    },
    //系统管理
    system: {
        //应用程序库
        app: {
            title: '應用標題',
            traditional: '繁體標題',
            english: '英語標題',
            address: '應用地址',
            type: '應用分類',
            open_mode: '打開方式',
            width: '窗口寬度',
            height: '窗口高度',
            select: '選擇',
            button_edit: '編輯應用按鈕',
            button_type_normal: '常規按鈕',
            button_type_list: '列表按鈕',
            delete_noselect: '請選擇要刪除的應用！',
            delete_confirm: '您確定要刪除所選應用嗎？',
            valid_title: '請輸入應用標題',
            valid_address: '請輸入應用地址',
            valid_type: '請選擇應用分類',
            valid_openmode: '請選擇應用打開方式',
            load_fail: '獲取應用程序數據失敗！',
            load_button_fail: '獲取應用程序按鈕失敗！',
            export_noselect: '您沒有選擇要導出的應用！',
            button: '按鈕',
        },
        //组织架构
        org: {
            name: '姓名',
            org: '組織',
            login_time: '登錄時間',
            activity_time: '活動時間',
            browser: '客戶端信息',
            login_type: '登錄類型',
            offline: '離線',
            pc: '電腦端',
            wechat: '企業微信',
            offline_confirm: '您確定要將該用戶強制離線嗎？',
            operation_success: '操作成功！',
            operation_fail: '操作失敗！',
            user_phone: '手機',
            user_mail: '郵箱',
            user_tel: '電話',
            user_birthday: '生日',
            user_concat: '其他聯繫方式',
            user_loadfail: '獲取用戶信息失敗！',
            user_editpass_old: '舊密碼',
            user_editpass_new: '新密碼',
            user_editpass_newconfirm: '確認新密碼',
            user_editpass_valid_newpass: '請輸入確認新密碼',
            user_editpass_valid_two: '兩次密碼輸入不一致',
            user_editpass_valid_old: '請輸入舊密碼',
            user_editpass_valid_new: '請輸入新密碼',
            user_editpass_success: '修改成功！',
            user_editpass_notfound: '未找到用戶！',
            user_editpass_olderror: '舊密碼錯誤！',
            user_editpass_newempty: '新密碼為空！',
            user_editpass_fail: '修改失敗！',
            user_sign: '簽章',
            user_sign_set: '設置',
            avatar_upload: '上傳',
            avatar_save: '保存頭像',
            avatar_default: '恢復默認',
            avatar_valid_picture: '頭像必須是圖片格式！',
            avatar_valid_size: '上傳的圖片文件不能大於2M！',
            avatar_no: '沒有要保存的頭像！',
            avatar_reset_success: '恢復成功！',
            avatar_reset_fail: '恢復失敗！',
            shortuct_tips: '提示：不能設置沒有綁定應用的菜單為快捷菜單。已選擇快捷菜單拖拽可排序。',
            shortuct_noselect: '請選擇要添加的菜單！',
            shortuct_nobind: '不能設置沒有綁定應用的菜單為快捷菜單！',
            shortuct_selected: '當前菜單已經選擇了！',
            shortuct_deletenoselect: '請選擇要移出的快捷菜單！',
            operation_tips: '請在左邊選擇要操作的組織',
            search: '搜索',
            search_note: '輸入關鍵字搜索',
            search_result: '未搜索到與“',
            search_result1: '”相關的結果',
            search_result2: '搜索“',
            search_result3: '”的結果',
            workgroup: '工作組',
            type: '類型',
            type_unit: '單位',
            type_dept: '部門',
            type_station: '崗位',
            status: '狀態',
            status_normal: '正常',
            status_frozen: '凍結',
            move: '移動',
            moveto: '移動到',
            moveto_note: '請選擇要移動到的組織',
            moveto_confirmnote: '您確定要移動嗎？',
            moveto_confirm: '確定移動',
            moveto_success: '移動成功！',
            moveto_empty: '要移動的組織為空！',
            moveto_self: '不能移動到自己或自己的下級組織！',
            adduser: '添加人員',
            setmenu: '菜單授權',
            setmenu_show: '查看菜單授權',
            syncwechat: '同步到企業微信',
            syncwechat_confirm: '您確定要同步整個組織架構到企業微信嗎？',
            syncwechat_completed: '同步完成！',
            syncwechat_fail: '同步失敗！',
            valid_name: '請輸入名稱',
            valid_type: '請選擇類型',
            valid_status: '請選擇狀態',
            org_notfound: '未找到組織架構！',
            save_confirm: '您確定要保存',
            save_confirm1: '為下級',
            save_confirm2: '嗎？',
            org_delete_confirm: '此操作將會刪除該組織及其所有下級組織，您確定要刪除嗎？',
            org_delete_root: '不能刪除組織架構根！',
            org_sort: '當前組織沒有下級組織，勿需排序！如要對人員排序，請點擊人員！',
            org_sortone: '當前組織只有一個下級組織，勿需排序！如要對人員排序，請點擊人員！',
            menu_source: '來源',
            account: '帳號',
            gender: '性別',
            gender_man: '男',
            gender_woman: '女',
            transfer: '調動到',
            transfer1: '調動',
            transfor_select: '請選擇要調往的組織',
            transfer_confirm: '您確定要調動嗎？',
            transfer_success: '調動成功！',
            transfer_fail: '調動失敗！',
            parttime: '兼任',
            transfor_confirm: '確定調動',
            inorg: '所在組織',
            initpassword: '初始密碼',
            initpassword_confirm: '您確定要初始化密碼嗎？',
            initpassword_tips: '密碼已初始化為：',
            initpassword_fail: '初始化密碼失敗！',
            valid_user_name: '請輸入姓名',
            valid_user_account: '請輸入帳號',
            valid_user_gender: '請選擇性別',
            valid_user_status: '請選擇狀態',
            valid_user_accountuse: '帳號已經被使用！',
            delete_confirm: '您確定要刪除嗎？',
            delete_no: '當前人員存在流程任務，不能刪除！',
            user_sort_one: '當前組織下只有一個人員，勿需排序！',
            user_load_fail: '加載人員錯誤！',
            member: '成員',
            workgroup_notfound: '未找到工作組！',
            iderror: 'id錯誤！',
            workgroup_deleteno: '不能刪除根工作組！',
        },
        //菜单管理
        menu: {
            name: '菜單名稱',
            traditional: '繁體名稱',
            english: '英語名稱',
            app: '關聯應用',
            icon: '菜單圖標',
            color: '圖標顏色',
            update_auth: '更新菜單權限',
            opeation_tips: '請點擊左邊菜單進行操作',
            valid_title: '請輸入菜單名稱',
            notfound: '未找到菜單！',
            load_fail: '加載數據失敗！',
            save_confirm: '您確定要保存',
            save_confirm1: '為下級',
            save_confirm2: '嗎？',
            delete_noselect: '請選擇要刪除的菜單！',
            delete_confirm: '此操作將會刪除該菜單及其所有下級菜單，您確定要刪除嗎？',
            update_success: '更新成功！',
            update_fail: '更新失敗！',
            sort_nochild: '當前菜單沒有下級菜單，勿需排序！',
            sort_one: '當前菜單只有一個下級菜單，勿需排序！',
            sort_success: '保存排序成功！',
            sort_fail: '保存排序失敗！',
        },
        //数据连接
        dbconn: {
            system: '系統連接',
            primarytable: '主表',
            name: '連接名稱',
            type: '連接類型',
            conn_string: '連接字符串',
            test: '測試',
            valid_name: '請輸入連接名稱',
            valid_type: '請選擇連接類型',
            valid_string: '請輸入連接字符串',
            delete_select: '請選擇要刪除的數據連接！',
            delete_confirm: '您確定要刪除所選數據連接嗎？',
            test_success: '連接數據庫成功！',
            test_fail: '連接數據庫失敗，請檢查數據庫服務器是否正常以及連接字符串是否正確！',
        },
        //系统按钮库
        button: {
            name: '名稱',
            name_tw: '繁體名稱',
            name_en: '英語名稱',
            icon: '圖標',
            script: '腳本',
            script_note: '按鈕點擊執行的js腳本',
            type: '類型',
            address: '操作地址',
            address_note: '按鈕對應的後端操作地址，驗證權限用。',
            valid_name: '請輸入按鈕名稱',
            valid_script: '請輸入按鈕腳本',
            delete_select: '請選擇要刪除的按鈕！',
            delete_confirm: '您確定要刪除所選按鈕嗎？',
        },
        //数据字典
        dict: {
            title: '字典標題',
            title_tw: '繁體標題',
            title_en: '英語標題',
            code: '唯一代碼',
            value: '字典值',
            other: '其他',
            status: '狀態',
            status_normal: '正常',
            status_delete: '作廢',
            op_note: '請點擊左邊字典項進行操作',
            valid_title: '請輸入字典標題',
            valid_code: '唯一代碼重複！',
            notfound: '未找到字典！',
            save_confirm: '您確定要保存',
            save_confirm1: '為下級',
            save_confirm2: '嗎？',
            delete_select: '請選擇要刪除的字典！',
            delete_confirm: '此操作將會刪除該字典及其所有下級字典，您確定要刪除嗎？',
            delete_notfound: '未找到要刪除的字典！',
            sort_not: '當前字典沒有下級字典，勿需排序！',
            sort_onenot: '當前字典只有一個下級字典，勿需排序！',
            export_select: '您沒有選擇要導出的字典！',
            import_file: '要導入的文件必須是json格式文件!',
        },
        //首页设置
        homeset: {
            name: '名稱',
            title: '標題',
            title_note: '模塊名稱或標題',
            type_top: '頂部統計',
            type_left: '左邊模塊',
            type_right: '右邊模塊',
            source: '數據來源',
            source_vue: 'VUE組件',
            source_dbconn: '數據連接',
            source_source: '來源',
            source_source_note: 'SQL或VUE組件名稱',
            use: '使用對象',
            use_note: '為空表示所有人可使用',
            link: '鏈接地址',
            color: '顏色',
            valid_name: '請輸入名稱',
            valid_title: '請輸入顯示標題',
            valid_type: '請選擇類型',
            valid_source: '請選擇數據來源',
            valid_source1: '請輸入來源',
            delete_noselect: '您沒有選擇要刪除的首頁設置！',
            delete_confirm: '您確定要刪除所選首頁設置嗎？',
            todo: '待辦事項',
        },
        //工作日设置
        weekday: {
            year: '年份',
            days: '共有工作日',
            days1: '天',
            save: '保存設置',
            tips: '提示：點擊單元格背景即設置為節假日',
            month: '月',
            Monday: '一',
            Tuesday: '二',
            Wednesday: '三',
            Thursday: '四',
            Friday: '五',
            Saturday: '六',
            Sunday: '日',
        },
        //序列号
        number: {
            title: '標題',
            format: '格式',
            format_note: '',
            digit: '位數',
            current: '當前值',
            type: '類型',
            type0: '不重置',
            type1: '年流水',
            type2: '月流水',
            type3: '日流水',
            valid_title: '請輸入標題',
            valid_digit: '請輸入位數',
            valid_type: '請選擇類型',
            delete_noselect: '請選擇要刪除的流水號！',
            delete_confirm: '您確定要刪除所選流水號嗎？',
        },
        //图标
        icon: {
            total: '共',
            total1: '個圖標，輸入圖標名稱可搜索。',
            copied: '已複製到剪貼板！',
        },
        //日志查询
        log: {
            title: '標題',
            type: '類型',
            date: '日期',
            operator: '操作人',
            details: '詳情',
            view_details: '查看日誌詳情',
            ip: '發生ip',
            url: '來源URL',
            url1: '操作URL',
            browse: '瀏覽器',
            contents: '日誌內容',
            before: '操作前',
            after: '操作後',
            other: '其他內容',
            type_login: '登錄日誌',
            type_system: '系統管理',
            type_flowmanage: '流程管理',
            type_flowrun: '流程運行',
            type_formmanage: '表單管理',
            type_other: '其他類型',
            type_error: '系統異常',
            notfound: '未找到日誌！',
        },
        //调查问卷
        quest: {
            subject: '主題',
            start_time: '開始時間',
            end_tiem: '結束時間',
            no_end_time: '無結束時間',
            publisher: '發布人',
            submit: '提交',
            add_subject: '添加選題',
            publish: '發布時間',
            publish1: '發布',
            submit_type: '提交方式',
            anonymous: '匿名',
            submit_confirm: '您確定要提交嗎？',
            submit_success: '提交成功！',
            submit_notfound: '沒有找到要提交的問卷！',
            submit_expire: '問卷已超過結束時間，不能提交！',
            submit_fail: '提交失敗！',
            create_time: '創建時間',
            participants: '參與人員',
            result_query_user: '結果查詢人員',
            status: '狀態',
            status_editing: '編輯中',
            status_published: '已發布',
            status_result: '已有結果',
            status_completed: '已完成',
            status_unknown: '未知',
            topic: '選題',
            end_tiem_note: '為空表示無結束時間',
            valid_subject: '主題不能為空',
            valid_participants: '參與人員不能為空',
            notfound: '未找到問卷信息！',
            loadfail: '獲取問卷信息失敗！',
            delete_confirm: '此操作將刪除該調查問卷所有數據，並且不可恢復，您確定要刪除嗎？',
            delete_result: '問卷已經有提交結果，不能刪除！',
            operation_confirm: '您確定要',
            operation_confirm1: '嗎？',
            success: '成功！',
            fail: '失敗！',
            publish_nosubject: '問卷沒有選題，不能發布！',
            cancel_result: '問卷已經有提交結果，不能取消！',
            topic_title: '選題標題',
            topic_selecttype: '選擇方式',
            topic_selecttype0: '單選',
            topic_selecttype1: '多選',
            topic_selecttype2: '文本框',
            topic_selecttype3: '文本域',
            topic_style: '樣式',
            topic_style1: '選題樣式',
            topic_input: '輸入',
            topic_input_no: '無',
            topic_valid_title: '請輸入選題標題',
            topic_loaderror: '加載選題錯誤！',
            result_subject: '按選題查看',
            result_user: '按人員查看',
            result_view: '查看',
            result_view_noauth: '您沒有權限查看問卷結果',
            submit_time: '提交時間',
            submit_no: '未提交',
        },
        //文件管理 
        files: {
            upload: '上傳文件',
            newfolder: '新建文件夾',
            rename: '重命名',
            move: '移動',
            share: '分享',
            delete: '刪除',
            search_note: '輸入關鍵字搜索',
            search: '搜索',
            name: '名稱',
            modifydate: '修改日期',
            type: '類型',
            folder: '文件夾',
            file: '文件',
            size: '大小',
            selectmoveto: '選擇要移動到的文件夾',
            fileshare: '文件分享',
            receiver: '接收人員',
            expiretime: '過期時間',
            expiretime_note: '為空表示不過期',
            inputfoldername: '請輸入文件夾名稱',
            foldernameempty: '文件夾名稱不能為空！',
            folder_empty: '目錄名稱為空！',
            folder_auth: '權限錯誤！',
            folder_exists: '目錄已經存在！',
            rename_select: '請選擇要重命名的文件或文件夾！',
            rename_inputname: '請輸入新的名稱',
            rename_valid: '名稱不能為空！',
            rename_fileemtpy: '文件或文件夾為空！',
            rename_empty: '名稱為空！',
            rename_nouser: '沒有登錄！',
            move_select: '請選擇要移動的文件或文件夾！',
            move_selectto: '請選擇要移動到的文件夾！',
            move_empty: '要移動到的文件或文件夾為空！',
            delete_select: '請選擇要刪除的文件或文件夾！',
            delete_confirm: '您確定要刪除嗎？',
            share_selectreceiver: '請選擇接收人',
            share_folder: '文件夾不能分享！',
            share_select: '請選擇要分享的文件！',
            share_success: '分享成功！',
            share_fail: '分享失敗！',
            share_cancel: '取消分享',
            share_time: '分享時間',
            share_long: '永久有效',
            share_isview: '查看',
            share_isview_yes: '是',
            share_isview_no: '否',
            share_cancel_select: '請選擇要取消分享的文件！',
            share_cancel_confirm: '您確定要取消分享嗎？',
            share_cancel_success: '取消分享成功！',
            share_cancel_fail: '取消分享失敗！',
            share_user: '分享人',
        },
        //内部邮箱
        mail: {
            receiver: '收件人',
            copyfor: '抄送',
            secret: '密送',
            subject: '主題',
            color: '顏色',
            attachment: '附件',
            contents: '正文',
            savedraft: '存草稿',
            send: '發送',
            send_valid_receiver: '請選擇收件人',
            send_valid_subject: '郵件主題不能為空',
            reply: '回復',
            redirect: '轉發',
            edit: '編輯',
            readlist: '閱讀情況',
            oldcontents: '原始郵件',
            sender: '發件人',
            date: '日期',
            send_confirm: '您確定要發送嗎？',
            valid_receiver: '接收人不能為空！',
            valid_subject: '郵件主題不能為空！',
            success: '成功！',
            fail: '失敗！',
            source: '來源',
            outbox: '發件箱',
            inbox: '收件箱',
            draftbox: '草稿箱',
            delete1_select: '請選擇要徹底刪除的郵件！',
            delete1_confirm: '您確定要徹底刪除所選郵件嗎？',
            delete_success: '刪除成功！',
            delete_fail: '刪除失敗！',
            delete: '刪除',
            deletedraft_select: '請選擇要刪除的草稿郵件！',
            areyousure: '您確定要',
            thorough: '徹底',
            deletedraft_confirm: '刪除所選草稿郵件嗎？',
            unread: '未讀',
            delete_select: '請選擇要刪除的郵件！',
            delete_confirm: '刪除所選郵件嗎？',
            delete_confirm1: '刪除郵件嗎？',
            view_notfound: '要查看的郵件不存在，請刷新列表！',
            view_noauth: '您無權查看該郵件！',
        },
    },
    //文档中心
    doc: {
        doc: '文檔中心',
        more: '更多',
        unread: '未讀',
        title: '標題',
        date: '日期',
        dir: '欄目',
        dir_edit: '編輯欄目',
        dir_add: '添加子欄目',
        dir_name: '欄目名稱',
        dir_name_traditional: '繁體名稱',
        dir_name_english: '英語名稱',
        dir_manager: '管理人員',
        dir_manager_note: '可以修改欄目和添加下級子欄目',
        dir_publisher_note: '可以發布和修改該欄目文檔',
        dir_reader: '閱讀人員',
        dir_reader_note: '可以閱讀查看該欄目文檔，為空表示所有人員都可閱讀。',
        dir_notfound: '未找到欄目！',
        dir_valid_name: '請輸入欄目名稱',
        dir_valid_manager: '請選擇管理人員',
        dir_valid_publisher: '請選擇發布人員',
        dir_valid_edit: '您沒有欄目編輯權限！',
        dir_valid_add: '您沒有添加子欄目權限！',
        dir_edit_noselect: '請選擇要編輯的欄目！',
        dir_delete_confirm: '您確定要刪除該欄目嗎？',
        dir_delete_valid: '沒有欄目刪除權限！',
        dir_delete_hasdoc: '該欄目下有文檔，不能刪除！',
        dir_delete_root: '不能刪除根欄目！',
        doc_publisher: '發布人',
        doc_publishtime: '發布時間',
        doc_level: '文檔等級',
        doc_normal: '普通',
        doc_attachment: '附件',
        doc_source: '來源',
        doc_contents: '內容',
        doc_valid_title: '請輸入文檔標題',
        doc_valid: '文檔欄目，標題，內容不能為空！',
        doc_valid_publish: '您沒有欄目發布文檔權限！',
        doc_valid_show: '您無權查看該文檔！',
        doc_loadfail: '加載文檔數據失敗！',
        doc_delete_noselect: '請選擇要刪除的文檔！',
        doc_delete_confirm: '您確定要刪除文檔嗎？',
        doc_delete_valid: '沒有權限刪除文檔！',
        doc_notfound: '未找到文檔！',
    },
    //应用程序设计
    program: {
        name: '應用名稱',
        name1: '名稱',
        type: '應用分類',
        type1: '分類',
        delete_noselect: '您沒有選擇要刪除的應用！',
        delete_confirm: '您確定要刪除所選應用嗎？',
        delete1_noselect: '您沒有選擇要徹底刪除的應用！',
        delete1_confirm: '您確定要徹底刪除所選應用嗎？',
        dbconn: '數據連接',
        editform: '編輯表單',
        selectapptype: '選擇應用分類',
        win_width: '窗口寬度',
        win_height: '窗口高度',
        button_Location: '按鈕位置',
        button_Location_row: '新行',
        button_Location_query: '查詢後面',
        select_row: '選擇列',
        select_row_no: '無',
        select_row_radio: '單選',
        select_row_checkbox: '多選',
        pager: '分頁',
        pager_no: '不分頁',
        pager_yes: '分頁',
        table_width: '表格寬度',
        table_height: '表格高度',
        table_size: '表格大小',
        table_border: '邊框',
        table_column: '列設置',
        table_column_note: 'el-table-column prop=name label=列名',
        scripts: '腳本',
        scripts_note: 'mounted中加載數據後執行',
        events: '事件',
        events_note: '添加修改刪除數據時執行的方法',
        sort: '默認排序',
        sort_note: '初次打開列表時的排序',
        attr_valid_name: '請輸入應用名稱',
        attr_valid_type: '請選擇分類',
        attr_valid_conn: '請選擇數據連接',
        attr_valid_sql: '請輸入SQL',
        load_fail: '加載數據失敗！',
        button_auth: '權限驗證',
        button_auth_no: '不驗證',
        button_auth_yes: '驗證',
        button_select: '選擇按鈕',
        button_showtype: '顯示類型',
        button_showtype_normal: '常規按鈕',
        button_showtype_list: '列表按鈕',
        button_showif: '顯示判斷',
        button_showif_note: '判斷按鈕是否顯示的js腳本',
        publish: '發布',
        publishing: '正在發布…',
        publish_success: '發布成功！',
        publish_notfound: '未找到要發布的應用！',
        publish_fail: '發布失敗！',
        return: '返回',
        attr: '屬性',
        list: '列表',
        button: '按鈕',
        validate: '驗證',
        field: '字段',
        field_name: '字段名稱',
        field_showname: '顯示名稱',
        field_showtype: '顯示方式',
        align: '對齊方式',
        export_copy: '複製列表字段',
        export_copy_confirm: '複製將清除現有導出字段設置，確定要複製嗎？',
        export_copy_success: '複製成功！',
        export_copy_iderror: '參數錯誤！',
        export_copy_nofield: '沒有要複製的列表字段！',
        export_datatype: '數據類型',
        export_format: '格式',
        export_delete_noselect: '您沒有選擇要刪除的導出字段！',
        export_delete_confirm: '您確定要刪除所選導出字段嗎？',
        custom: '自定義',
        custom_note: '自定義字符串',
        width: '寬度',
        list_isshow: '隱藏',
        list_issort: '排序',
        list_forze: '凍結列',
        list_forze_no: '無',
        list_forze_left: '左',
        list_forze_right: '右',
        list_showstyle: '顯示樣式',
        list_showstyle_note: 'css樣式',
        list_onlybuttoncolumn: '一個應用只能添加一個按鈕列！',
        list_delete_noselect: '您沒有選擇要刪除的列表！',
        list_delete_confirm: '您確定要刪除所選列表嗎？',
        operator: '操作符',
        control_name: '控件名稱',
        input_type: '輸入類型',
        placeholder: '背景文字',
        datasource: '數據來源',
        source: '來源',
        source_note: '字符串表達式/SQL語句/URL地址',
        source_dict: '數據字典',
        valuefield: '值字段',
        select_range: '選擇範圍',
        select_type: '選擇類型',
        select_size: '選擇個數',
        query_numberid: '查詢時轉數字id',
        string_expression: '字符串表達式',
        all: '全部',
        query_delete_noselect: '您沒有選擇要刪除的查詢！',
        query_delete_confirm: '您確定要刪除所選查詢嗎？',
        valid_all_status: '將字段狀態設置為',
        valid_all_check: '將字段檢查設置為',
        table_name: '表名',
        field_note: '字段說明',
        field_status: '字段狀態',
        valid_type: '驗證類型',
        status_edit: '編輯',
        status_readonly: '只讀',
        status_hidden: '隱藏',
        check_check: '檢查',
        check_notcheck: '不檢查',
        check_notfoundapp: '未找到應用程序設計！',
        recovery_confirm: '您確定要恢復該應用嗎？',
        run_delete_noselect: '您沒有選擇要刪除的數據！',
        run_delete_confirm: '您確定要刪除所選數據嗎？',
        run_delete_noform: '應用未設置表單！',
        rownumber: '序號',
        export_headtext: '導出標題',
        export_headtext_note: '導出Excel文件的標題',
        export_filename: '文件名稱',
        export_filename_note: '導出Excel文件的文件名',
        export_template: '模板文件',
        import_table: '導入表',
        import_table_note: '導入Excel文件對應的數據表名',
        import_titlefield: '標識列',
        import_titlefield_note: '標識單次導入的標識字段',
        import_filetypeerr: '要導入的文件必須是excel格式文件!',
        import_dialogtitle: '導入',
        import_dialogselectfile: '選擇文件',
        import_notfoundmodel: '未找到程序運行時！',
        import_nosettable: '未設置要導入的表！',
        import_nosetdbconn: '未設置數據連接！',
        import_error: '導入發生錯誤！請聯繫管理員！',
    },
}